document.addEventListener('DOMContentLoaded', () => {
    const boxes = Array.from(document.querySelectorAll('.js-two-cards-section__box'));

    boxes.forEach(box => {
       box.addEventListener('mouseover', () => {
           boxes.forEach(boxFromList => {
               if(boxFromList !== box) {
                   boxFromList.classList.add('is-fade');
               }
           });
       });
        box.addEventListener('mouseout', () => {
            boxes.forEach(boxFromList => {
                boxFromList.classList.remove('is-fade');
            });
        });
    });
});
import Swiper from 'swiper/swiper-bundle';

class BlogPostsSection {
  constructor(element) {
    this.element = element;

    if (this.isMobile()) {
      this.initSwiper();
    }
  }

  initSwiper() {
    this.slider = new Swiper(this.element.querySelector('.swiper-container'), {
      slidesPerView: 1.4,
      resizeObserver: true,
      speed: 500
    });
  }

  isMobile() {
    return window.innerWidth <= 768;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-blog-posts-section');

  if (elements) {
    elements.forEach(element => {
      new BlogPostsSection(element);
    });
  }
});
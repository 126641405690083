class Input {
    constructor(el) {
        this.el = el;
        this.input = this.el.querySelector('.js-input__field');

        this.input.addEventListener('blur', (e) => {
            this.dispatchEvent('field-blur', e.target.value);
        });
        this.input.addEventListener('change', (e) => {
            this.dispatchEvent('field-change', e.target.value);
        });
    }

    dispatchEvent(name, value) {
        const event = new CustomEvent(name, {
            detail: {
                value: value
            }
        });
        this.el.dispatchEvent(event);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const elements = document.querySelectorAll('.js-input');
    elements.forEach((el) => {
        new Input(el);
    });
});

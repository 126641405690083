class FeatureList {
  constructor(element) {
    this.element = element;
    this.players = element.querySelectorAll('.js-feature-list__player');

    if (this.players) {
      this.handlePlayers();
    }
  }

  handlePlayers() {
    let currentPlaying = null;

    this.players.forEach(player => {
      const play = player.querySelector('.js-feature-list__player-icon-play');
      const pause = player.querySelector('.js-feature-list__player-icon-pause');
      const audioFile = player.querySelector('.js-feature-list__player-file');

      play.addEventListener('click', () => {
        if (currentPlaying) {
          const pause = currentPlaying.querySelector('.js-feature-list__player-icon-pause');
          pause.click();
        }

        player.classList.add('is-playing');
        play.classList.remove('is-active');
        pause.classList.add('is-active');

        audioFile.play();
        currentPlaying = player;
      });

      pause.addEventListener('click', () => {
        player.classList.remove('is-playing');
        pause.classList.remove('is-active');
        play.classList.add('is-active');

        audioFile.pause();
        currentPlaying = null;
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-feature-list');

  if (elements) {
    elements.forEach(element => {
      new FeatureList(element);
    });
  }
});
class ReferencesSection {
  constructor(element) {
    this.element = element;
    this.tiles = element.querySelectorAll('.js-references-section__tile');

    this.handleTiles();
  }

  handleTiles() {
    // Return if no tiles
    if (!this.tiles) return;

    this.tiles.forEach((tile) => this.handleTile(tile));
  }

  handleTile(tile) {
    const index = tile.dataset.index;
    const id = tile.id;
    const openButton = tile.querySelector('.js-references-section__tile-button');
    const popup = this.element.querySelector(`.js-references-section__popup[data-index="${index}"]`);

    // Return if no popup
    if (!popup) return;

    const closeButton = popup.querySelector('.js-references-section__popup-close');

    // Open popup on click tile button
    if (openButton) {
      openButton.addEventListener('click', () => {
        this.openPopup(popup);
      });
    }

    // Close popup on click close button
    if (closeButton) {
      closeButton.addEventListener('click', () => {
        this.closePopup(popup);
      });
    }

    // Close popup on click outside
    popup.addEventListener('click', (e) => {
      if (e.target === popup) {
        this.closePopup(popup);
      }
    });

    // Close popup on escape key
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.closePopup(popup);
      }
    });

    // Open popup if URL contains hash equal to tile id
    if (id && window.location.hash === `#${id}`) {
      this.openPopup(popup);
    }
  }

  openPopup(popup) {
    popup.classList.add('is-active');

    if (this.isMobile()) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
  }

  closePopup(popup) {
    popup.classList.remove('is-active');

    if (this.isMobile()) {
      document.getElementsByTagName('html')[0].style.overflow = '';
    }
  }

  isMobile() {
    return window.innerWidth <= 768;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-references-section');
  if (elements) {
    elements.forEach(element => {
      new ReferencesSection(element);
    });
  }
});
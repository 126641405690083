class ShortQuotesSection {
  constructor(element) {
    this.element = element;

    this.handleAnimation();
  }

  handleAnimation() {
    if (!this.element) return;

    const isAnimation = this.element.dataset.animation;

    if (isAnimation == 'false') return;

    this.registerAnimation();
  }

  registerAnimation() {
    this.columns = this.element.querySelectorAll('.js-short-quotes-section__items-col');

    if (!this.columns) return;

    this.animationIntervalSpeed = 20;
    this.columnAnimationSpeed = 0.5;
    this.highestColumn = {};

    this.columns.forEach((column) => {
      if (!column.style.transform) {
        column.style.transform = 'translateY(0)';
      }

      const styles = window.getComputedStyle(column);
      const marginTop = parseFloat(styles['marginTop']);

      if (!this.highestColumn.height || this.highestColumn.height < column.offsetHeight + marginTop) {
        this.highestColumn.element = column;
        this.highestColumn.height = column.offsetHeight + marginTop;
      }
    });

    this.registerIntersectionObserver();
    this.registerMouseEvents();
  }

  registerIntersectionObserver() {
    let options = {};

    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.startAnimation();
        } else {
          this.stopAnimation();
        }
      });
    }, options);

    observer.observe(this.element);
  }

  registerMouseEvents() {
    if (!this.element) return;

    const items = this.element.querySelectorAll('.o-short-quotes-section__item');

    if (!items) return;

    items.forEach(item => {
      item.addEventListener('mouseover', () => {
        this.stopAnimation();
      });

      item.addEventListener('mouseleave', () => {
        this.startAnimation();
      });
    });
  }

  startAnimation() {
    if (!this.columns) return;

    this.animationInterval = setInterval(() => {
      this.columns.forEach(column => {
        const currentTranslateY = +column.style.transform.match(/translateY\((.*?)px\)/)[1] || 0;
        let newTranslateY = currentTranslateY - this.columnAnimationSpeed;
        column.style.transform = `translateY(${newTranslateY}px)`;
      });

      const lastPosition = this.highestColumn.element.getBoundingClientRect().bottom;

      if (lastPosition <= this.element.getBoundingClientRect().bottom - 50) {
        this.stopAnimation();
      }
    }, this.animationIntervalSpeed);
  }

  stopAnimation() {
    if (!this.animationInterval) return;

    clearInterval(this.animationInterval);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-short-quotes-section');

  if (elements) {
    elements.forEach(element => {
      new ShortQuotesSection(element);
    });
  }
});
class RequestDemoForm {
  constructor(element) {
    this.element = element;
    this.heading = element.querySelector('.js-request-demo-form__heading');
    this.tierListLevel = this.getTierListLevel();

    if (this.tierListLevel && this.heading) {
      this.addTierListLevelToFormHeading();
    }
  }

  getTierListLevel() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    let tier = url.searchParams.get("tier");
    const isNewLine = url.searchParams.get("nl");

    if (isNewLine) {
      tier = '<br>' + tier;
    }

    return tier;
  }

  addTierListLevelToFormHeading() {
    this.heading.innerHTML += `: ${this.tierListLevel}`;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-request-demo-form');
  if (elements) {
    elements.forEach(element => {
      new RequestDemoForm(element);
    });
  }
});
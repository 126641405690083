import { gsap } from "gsap";

class PlayerSection {
  constructor(element) {
    this.element = element;
    this.buttons = element.querySelectorAll('.js-player-section__change-button');
    this.playerWrappers = element.querySelectorAll('.js-player-section__player-wrapper');

    if (this.buttons) {
      this.buttons.forEach(button => {
        button.addEventListener('click', this.handleChangeButtonClick);
      });
    }
  }

  handleChangeButtonClick = (e) => {
    const newButton = e.target;
    const newID = newButton.dataset.id;
    const newPlayerWrapper = this.getPlayerWrapperByID(newID);
    const newPlayerContent = this.getPlayerContentByID(newID) ?? this.getFirstPlayerContent();

    const oldButton = this.getActiveButton();
    const oldPlayerWrapper = this.getActivePlayerWrapper();
    const oldPlayerContent = this.getActivePlayerContent();

    if (newButton == oldButton) {
      return;
    }

    if (oldButton) {
      this.removeActiveClass(oldButton);
    }
    this.addActiveClass(newButton);

    if (oldPlayerWrapper) {
      this.removeActiveClass(oldPlayerWrapper);
    }
    this.addActiveClass(newPlayerWrapper);

    if (oldPlayerContent) {
      this.removeActiveClass(oldPlayerContent);
    }
    this.addActiveClass(newPlayerContent);

    this.scaleUpAnimation(newPlayerWrapper);
  }

  getActiveButton = () => {
    return this.element.querySelector('.js-player-section__change-button.is-active');
  }

  getActivePlayerWrapper = () => {
    return this.element.querySelector('.js-player-section__player-wrapper.is-active');
  }

  getFirstPlayerContent = () => {
    return this.element.querySelector('.o-player-section__col--content');
  }

  getActivePlayerContent = () => {
    return this.element.querySelector('.o-player-section__col--content.is-active');
  }

  getPlayerWrapperByID = (id) => {
    return this.element.querySelector(`.js-player-section__player-wrapper[data-id="${id}"]`);
  }

  getPlayerContentByID = (id) => {
    return this.element.querySelector(`.js-player-section__col--additional[data-id="${id}"]`);
  }

  addActiveClass = (element) => {
    element.classList.add('is-active');
  }

  removeActiveClass = (element) => {
    element.classList.remove('is-active');
  }

  scaleUpAnimation = (element) => {
    gsap.timeline()
      .fromTo(element, { scaleX: 0.95, scaleY: 0.95 }, { scaleX: 1.05, scaleY: 1.05, duration: 0.3 })
      .to(element, { scaleX: 1, scaleY: 1, duration: 0.15 });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-player-section');
  if (elements) {
    elements.forEach(element => {
      new PlayerSection(element);
    });
  }
});
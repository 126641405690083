import LanguageSwitcher from '../../atoms/language-switcher/language-switcher';

class Header {
  constructor() {
    this.el = document.querySelector('.js-header');
    this.burgerEls = this.el.querySelectorAll('.js-header__hamburger');
    this.sideNavEl = this.el.querySelector('.js-header__side-nav');
    this.mobileSubMenuEls = this.el.querySelectorAll('.submenu__item--with-children');
    this.LanguageSwitcherMobile = new LanguageSwitcher('.js-language-switcher--mobile.js-language-switcher');

    this.isHeaderSticky = false;

    this.burgerEls.forEach(burgerEl => {
      burgerEl.addEventListener('click', () => {
        if (this.LanguageSwitcherMobile.isMobilePopupActive) {
          this.LanguageSwitcherMobile.deactiveMobilePopup();
        } else {
          this.toggleHamburger();
          document.getElementsByTagName('html')[0].style.overflow = document.getElementsByTagName('html')[0].style.overflow === 'hidden' ? '' : 'hidden';

          if (!burgerEl.classList.contains('is-tier-list-active')) {
            this.toggleActive();
            this.toggleSideNav();
          }
        }
      });
    });

    this.toggleHeaderSticky();
    this.toggleSubMenu();
    this.handleAdditionalContentPopup();
    this.handleFeaturedButton();

    window.addEventListener('scroll', this.toggleHeaderSticky)
  }

  toggleHeaderSticky = () => {
    const distance = this.el.offsetTop + 10 - window.pageYOffset;
    const offset = window.pageYOffset;
    if ((distance <= 0) && !this.isHeaderSticky) {
      this.isHeaderSticky = true;
      this.el.classList.add('is-sticky');
    } else if (this.isHeaderSticky && (offset <= this.el.offsetTop + 10)) {
      this.isHeaderSticky = false;
      this.el.classList.remove('is-sticky');
    }
  }

  toggleActive() {
    this.el.classList.toggle('is-active');
  }

  toggleSideNav() {
    this.sideNavEl.classList.toggle('is-active');
  }

  closeActiveSubMenus() {
    if (this.mobileSubMenuEls) {
      this.mobileSubMenuEls.forEach(mobileSubMenuEl => {
        if (mobileSubMenuEl.classList.contains('is-active')) {
          mobileSubMenuEl.classList.remove('is-active');
        }
      });
    }
  }

  toggleSubMenu() {
    this.mobileSubMenuEls.forEach(subMenuEl => {
      subMenuEl.addEventListener('click', () => {
        if (subMenuEl.classList.contains('is-active')) {
          subMenuEl.classList.remove('is-active');
        } else {
          this.closeActiveSubMenus();
          subMenuEl.classList.add('is-active');
        }
      });
    });
  }

  toggleHamburger() {
    this.burgerEls.forEach(burgerEl => {
      burgerEl.classList.toggle('is-active');
    });
  }

  handleFeaturedButton() {
    const headerFeaturedButton = document.querySelector('.js-main-menu-featured-link');

    if (!headerFeaturedButton) {
      return;
    }

    const headerFeaturedButtonWidth = headerFeaturedButton.offsetWidth;

    if (headerFeaturedButtonWidth > 0) {
      document.documentElement.style.setProperty('--main-menu-featured-button', (headerFeaturedButtonWidth / 8) + 'rem');
    }
  }

  handleAdditionalContentPopup() {
    if (!this.el) return;

    const additionalContentPopup = this.el.querySelector('.js-menu__item--additional-content-on-hover');

    if (!additionalContentPopup) return;

    additionalContentPopup.addEventListener('mouseenter', () => {
      document.body.classList.add('is-overlay');
    });

    additionalContentPopup.addEventListener('mouseleave', () => {
      document.body.classList.remove('is-overlay');
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const header = new Header();
});

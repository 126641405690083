import { ScrollToSection } from "../../../../../assets/private/js/scrollToSection";

class FormSectionV2 {
  constructor(element) {
    this.element = element;
    this.subheading = element.querySelector('.js-form-section-v2__subheading');
    this.formButton = element.querySelector('.js-form__button');
    this.stickyButton = null;
    this.tierListLevel = this.getTierListLevel();

    this.isSectionIntersection = false;
    this.isFormButtonIntersection = false;

    if (this.tierListLevel && this.subheading) {
      this.addTierListLevelToFormHeading();
    }

    this.createStickyButton();
    this.registerIntersectionObserver();
  }

  stickyButtonHandler() {
    if (this.isSectionIntersection) {
      if (this.isFormButtonIntersection) {
        this.hideStickyButton();
      } else {
        this.showStickyButton();
      }
    } else {
      this.hideStickyButton();
    }
  }

  getTierListLevel() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    let tier = url.searchParams.get("tier");

    return tier;
  }

  addTierListLevelToFormHeading() {
    this.subheading.innerHTML += this.tierListLevel;
  }

  createStickyButton() {
    let wrapper = document.createElement('div');
    wrapper.className = 'o-form-section-v2__sticky is-hidden';

    let button = document.createElement('a');
    button.className = 'a-button a-button--primary o-form-section-v2__sticky-link';
    button.href = '#form-section-v2-form';
    button.textContent = this.formButton.textContent;

    wrapper.appendChild(button);

    document.body.appendChild(wrapper);

    this.stickyButton = wrapper;

    button.addEventListener('click', (event) => {
      event.preventDefault();

      const link = event.target;
      const href = link.getAttribute("href");

      const targetSection = document.querySelector(href);

      const header = document.querySelector('.js-header');
      let headerHeight = 0;
      if (header) {
        headerHeight = header.offsetHeight;
      }

      ScrollToSection.scroll(targetSection.getBoundingClientRect().top - headerHeight - document.body.getBoundingClientRect().top);
    });
  }

  registerIntersectionObserver() {
    let sectionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        this.isSectionIntersection = entry.isIntersecting;
        this.stickyButtonHandler();
      });
    }, {
      rootMargin: '-30% 0px',
      threshold: [0]
    });
    sectionObserver.observe(this.element);

    let formButtonObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        this.isFormButtonIntersection = entry.isIntersecting;
        this.stickyButtonHandler();
      });
    }, {
      rootMargin: '0px',
      threshold: [0]
    });
    formButtonObserver.observe(this.formButton);
  }

  hideStickyButton() {
    if (this.stickyButton) {
      this.stickyButton.classList.add('is-hidden');
    }
  }

  showStickyButton() {
    if (this.stickyButton) {
      this.stickyButton.classList.remove('is-hidden');
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-form-section-v2');
  if (elements) {
    elements.forEach(element => {
      new FormSectionV2(element);
    });
  }
});
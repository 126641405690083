import { ScrollToSection } from "../../../../../assets/private/js/scrollToSection";
import TestimonialSection from "../../organisms/testimonial-section/testimonial-section";

class SocialProofSection {
  constructor(element) {
    this.element = element;
    this.postID = element.dataset.postId;
    this.blockID = element.dataset.blockId;
    this.listItems = element.querySelectorAll('.js-social-proof-categories-section__list-item');
    this.navigationItems = element.querySelectorAll('.js-social-proof-categories-section__navigation-item');
    this.contentTtems = element.querySelectorAll('.js-social-proof-categories-section__item');

    this.calcHeights();
    this.initObserver();
  }

  initObserver() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.initOnScroll();
          observer.unobserve(entry.target);
        }
      });
    }, {
      rootMargin: '0px 0px 0px 0px',
      threshold: 0
    });

    observer.observe(this.element);
  }

  initOnScroll() {
    this.handleNavigationItemClick();
    this.initTestimonialSection();
  }

  calcHeights() {
    if (this.isMobile() || !this.navigationItems) return;

    let navigationItemsHeight = 0;

    this.navigationItems.forEach(navigationItem => {
      navigationItemsHeight += navigationItem.offsetHeight;
    });

    const itemsWrapper = this.element.querySelector('.js-social-proof-categories-section__items');

    if (!itemsWrapper) return;

    let itemsWrapperHeight = itemsWrapper.offsetHeight;

    if (itemsWrapperHeight > navigationItemsHeight) {
      this.element.classList.add('is-short-navigation');
    } else {
      this.element.classList.remove('is-short-navigation');
    }
  }

  handleNavigationItemClick() {
    if (!this.navigationItems) return;

    this.navigationItems.forEach(navigationItem => {
      navigationItem.addEventListener('click', () => {
        if (navigationItem.classList.contains('is-active')) return;

        const index = navigationItem.dataset.index;

        const activeNavigationItem = this.getActiveNavigationItem();
        if (activeNavigationItem) {
          this.removeActiveClass(activeNavigationItem);
        }

        this.addActiveClass(navigationItem);

        const activeListItem = this.getActiveListItem();
        if (activeListItem) {
          this.removeActiveClass(activeListItem);
        }

        const activeContentItem = this.getActiveContentItem();
        if (activeContentItem) {
          this.removeActiveClass(activeContentItem);
        }

        const newListItem = this.getListItemByIndex(index);
        if (newListItem) {
          this.addActiveClass(newListItem);
        }

        const newContentItem = this.getContentItemByIndex(index);
        if (newContentItem) {
          this.addActiveClass(newContentItem);
        }

        this.calcHeights();
        this.scrollToActiveItem();
        this.initTestimonialSection();
      });
    });
  }

  getActiveNavigationItem() {
    return this.element.querySelector('.js-social-proof-categories-section__navigation-item.is-active');
  }

  getActiveListItem() {
    return this.element.querySelector('.js-social-proof-categories-section__list-item.is-active');
  }

  getActiveContentItem() {
    return this.element.querySelector('.js-social-proof-categories-section__item.is-active');
  }

  getListItemByIndex(index) {
    if (!index) return;

    return this.element.querySelector(`.js-social-proof-categories-section__list-item[data-index="${index}"]`);
  }

  getContentItemByIndex(index) {
    if (!index) return;

    return this.element.querySelector(`.js-social-proof-categories-section__item[data-index="${index}"]`);
  }

  addActiveClass(element) {
    if (!element) return;

    element.classList.add('is-active');
  }

  removeActiveClass(element) {
    if (!element) return;

    element.classList.remove('is-active');
  }

  isMobile() {
    return window.innerWidth <= 768;
  }

  scrollToActiveItem() {
    // return if its not mobile
    if (!this.isMobile()) return;

    // get active list item
    const activeListItem = this.getActiveListItem();

    // return if there is no active list item
    if (!activeListItem) return;

    // get header offset height
    const headerOffsetHeight = document.querySelector('.js-header').offsetHeight;

    // get body offset
    const bodyOffset = document.body.getBoundingClientRect();

    // scroll to active list item
    ScrollToSection.scroll(activeListItem.getBoundingClientRect().top - bodyOffset.top - headerOffsetHeight);
  }

  initTestimonialSection() {
    const sections = document.querySelectorAll('.js-social-proof-categories-section__item.is-active .js-testimonial-section--social-proof');

    if (sections) {
      sections.forEach(section => {
        const prevButton = section.querySelector('.swiper-button-prev');
        const nextButton = section.querySelector('.swiper-button-next');
        const pagination = section.querySelector('.swiper-pagination');

        new TestimonialSection(section, prevButton, nextButton, pagination);
      });
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-social-proof-categories-section');
  if (elements) {
    elements.forEach(element => {
      new SocialProofSection(element);
    });
  }
});
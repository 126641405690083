class FaqSection {
  constructor(element) {
    this.element = element;
    this.faqItems = element.querySelectorAll('.js-faq-section__item');

    if (this.faqItems) {
      this.faqItems.forEach(faqItem => {
        this.handleFaqItem(faqItem);
      });
    }
  }

  handleFaqItem(faqItem) {
    const toggler = faqItem.querySelector('.js-faq-section__toggler');
    if (toggler) {
      toggler.addEventListener('click', () => this.handleFaqItemTogglerClick(faqItem));
    }
  }

  handleFaqItemTogglerClick(faqItem) {
    if (this.activeFaqItem && this.activeFaqItem !== faqItem) {
      this.activeFaqItem.classList.remove('is-active');
    }

    faqItem.classList.toggle('is-active');

    if (faqItem.classList.contains('is-active')) {
      this.activeFaqItem = faqItem;
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-faq-section');
  if (elements) {
    elements.forEach(element => {
      new FaqSection(element);
    });
  }
});
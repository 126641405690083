import Choices from 'choices.js';

class Select {
  constructor(el) {
    this.el = el;
    this.select = this.el.querySelector('.js-select__select');

    new Choices(this.select, {
      searchEnabled: false,
      itemSelectText: '',
      shouldSort: false,
    });

    this.select.addEventListener('change', (event) => {
      const isCustomTextOption = event.currentTarget.selectedOptions[0].dataset.customProperties;

      const selectId = this.select.getAttribute('id');

      if (!selectId) return;

      const customTextOption = document.querySelector('.js-select__custom-text-option_' + selectId);

      if (!customTextOption) return;

      if (isCustomTextOption && isCustomTextOption === 'true') {
        customTextOption.classList.add('is-active');
      } else {
        customTextOption.classList.remove('is-active');
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-select');
  elements.forEach((el) => {
    new Select(el);
  });
});

import { ScrollToSection } from "../../../../../assets/private/js/scrollToSection";

class TableOfContents {
  constructor(element) {
    this.element = element;
    this.listItems = element.querySelectorAll('.js-table-of-contents__list-item');
    this.listItemLinks = element.querySelectorAll('.js-table-of-contents__list-item-link');
    this.popup = document.querySelector('.js-table-of-contents-popup');
    this.openPopupButtons = document.querySelectorAll('.js-open-table-of-contents-popup');
    this.closePopupButtons = document.querySelectorAll('.js-close-table-of-contents-popup');
    this.domHeadings = [];

    this.handlePopup();
    this.handleListItems();

    window.addEventListener('scroll', () => {
      const header = document.querySelector('.js-header');
      if (header && header.classList.contains('is-sticky')) {
        setTimeout(() => {
          this.registerIntersectionObserver();
        }, 300);
      }
    });
  }

  handlePopup() {
    if (!this.popup) {
      return;
    }

    if (this.openPopupButtons) {
      this.openPopupButtons.forEach(openPopupButton => {
        openPopupButton.addEventListener('click', () => this.openPopup());
      });
    }

    if (this.closePopupButtons) {
      this.closePopupButtons.forEach(closePopupButton => {
        closePopupButton.addEventListener('click', () => this.closePopup());
      });
    }
  }

  openPopup() {
    if (!this.popup) {
      return;
    }

    this.popup.classList.add('is-active');
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  }

  closePopup() {
    if (!this.popup) {
      return;
    }

    this.popup.classList.remove('is-active');
    document.getElementsByTagName('html')[0].style.overflow = '';
  }

  handleListItems() {
    if (!this.listItems) {
      return;
    }

    this.bindItems();
  }

  bindItems() {
    if (!this.listItemLinks) {
      return;
    }

    this.listItemLinks.forEach(listItemLink => {
      const target = listItemLink.dataset.target;
      const xpath = "//*[text()='" + target + "']";
      const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
      if (matchingElement) {
        matchingElement.id = target;
        this.domHeadings.push(matchingElement);
      }

      listItemLink.addEventListener('click', () => this.handleListItemLinkClick(listItemLink));
    });
  }

  handleListItemLinkClick(element) {
    this.closePopup();

    const target = element.dataset.target;
    const xpath = "//*[text()='" + target + "']";
    const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    if (matchingElement) {
      const header = document.querySelector('.js-header');
      let headerHeight = 0;
      if (header) {
        headerHeight = header.offsetHeight;
      }

      ScrollToSection.scroll(matchingElement.getBoundingClientRect().top - headerHeight - document.body.getBoundingClientRect().top - 16);
    }
  }

  registerIntersectionObserver() {
    if (!this.domHeadings) {
      return;
    }

    const header = document.querySelector('.js-header');
    let headerHeight = 0;
    if (header) {
      headerHeight = header.offsetHeight;
    }

    let items = {};

    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const elementID = entry.target.id;
        let isIntersecting = entry.isIntersecting;

        items[elementID] = isIntersecting;

        for (const [key, value] of Object.entries(items)) {
          if (value == true) {
            const oldElement = this.element.querySelector('.js-table-of-contents__list-item-link.is-active');
            if (oldElement) {
              oldElement.classList.remove('is-active');
            }
            const newElement = this.element.querySelector('.js-table-of-contents__list-item-link[data-target="' + key + '"]')
            if (newElement) {
              newElement.classList.add('is-active');
            }
            break;
          }
        }
      });
    }, {
      rootMargin: -headerHeight + 'px',
      threshold: 1.0
    });

    this.domHeadings.forEach(domHeading => {
      observer.observe(domHeading);
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-table-of-contents');
  if (elements) {
    elements.forEach(element => {
      new TableOfContents(element);
    });
  }
});
import axios from 'axios';
import qs from 'qs';
import { ScrollToSection } from "../../../../../assets/private/js/scrollToSection";
import intlTelInput from 'intl-tel-input';

export class Form {
  constructor(formElement, formAction, validationConfiguration, functions) {
    this.el = formElement;
    this.formAction = formAction;
    this.siteDomain = this.el.dataset.domain;
    this.formLanguage = this.el.dataset.language;
    this.validationConfiguration = validationConfiguration;
    this.isBusinessEmailValidationDisabled = this.el.dataset.disablebusinessemailvalidation ? true : false;
    this.functions = functions ?? {};

    if (this.isBusinessEmailValidationDisabled === true) {
      this.validationConfiguration.email.validators[1].isGenericEmail = () => {
        return true;
      }
    }


    this.registerEventListenersForFormFields();
    this.registerInternationalTelephoneInput();
    this.el.addEventListener('submit', event => this.submit.bind(this)(event));

    this.saveTierListLevelToForm();
  }

  saveTierListLevelToForm() {
    const input = this.el.querySelector('input[name="tierList"]');
    let tierListIndex = sessionStorage.getItem('tierListIndex');

    if (input && tierListIndex) {
      input.value = tierListIndex;
    }
  }

  registerEventListenersForFormFields() {
    //  Attach validation to custom change/blur event
    Array.from(this.el.querySelectorAll('[data-field-name]')).map(field => {
      const validator = this.validationConfiguration[field.dataset.fieldName];
      if (validator) {
        field.addEventListener(validator.triggerEvent || 'field-blur', (event) => {
          this.validateField(validator, field.dataset.fieldName, event.detail.value);
        });
      }
    });
  }

  validateField(validator, fieldName, fieldValue) {
    let error = validator.validators
      .filter(validator => !validator.validate(fieldValue, fieldName));

    if (fieldName == 'email') {
      var genericEmailError;

      validator.validators
        .filter(validator => {
          if (validator.isGenericEmail) {
            genericEmailError = !validator.isGenericEmail(fieldValue, fieldName);
          }
        });
    }

    let field = this.el.querySelector(`[data-field-name="${fieldName}"]`);

    if (field) {
      if (error.length > 0 || genericEmailError == true) {
        field.classList.add('has-error');
      } else {
        field.classList.remove('has-error');
      }

      let errorLength = error.length;
      if (genericEmailError === true) {
        errorLength = errorLength + 1;
      }

      return !!errorLength;
    } else {
      console.error('Field ' + fieldName + ' not found!');
      return false;
    }
  }

  validateErrorTypes(validator, fieldName, fieldValue) {
    let errorTypes = [];

    if (fieldName == 'email') {
      validator.validators.forEach(validator => {
        if (typeof validator.isGenericEmail === 'function') {
          if (!validator.isGenericEmail(fieldValue)) {
            errorTypes.push('generic-email');
          }
        }
      });
    }

    if (errorTypes.length > 0) {
      return errorTypes;
    } else {
      return false;
    }
  }

  registerInternationalTelephoneInput() {
    const inputs = document.querySelectorAll(".js-intl-tel input[type='tel']");
    if (inputs) {
      let initialCountry = 'us';
      let preferredCountries = ['us', 'gb'];

      switch (this.formLanguage) {
        case 'pl':
          initialCountry = 'pl';
          preferredCountries = ['pl', 'us', 'gb'];
          break;
        case 'de':
          initialCountry = 'de';
          preferredCountries = ['de', 'at', 'ch'];
          break;
        case 'fr':
          initialCountry = 'fr';
          preferredCountries = ['fr', 'ch', 'be'];
          break;
        case 'es':
          initialCountry = 'es';
          preferredCountries = ['es', 'mx', 'co'];
          break;
      }

      inputs.forEach(input => {
        intlTelInput(input, {
          preferredCountries,
          initialCountry,
        });
      });
    }
  }

  getInternationalTelephoneCountryCode() {
    let countryCode = '';

    const selectedCountry = document.querySelector('.iti__selected-flag');
    if (selectedCountry) {
      countryCode = selectedCountry.title;
    }

    return countryCode;
  }

  submit(event) {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(event.target);
    let errorCount = 0;
    let errorTypes = '';

    //  Validate all fields
    Object.keys(this.validationConfiguration).forEach(fieldName => {
      this.validateField(
        this.validationConfiguration[fieldName],
        fieldName,
        (fieldName.includes('[]') ? formData.getAll(fieldName) : formData.get(fieldName))
      ) ? errorCount++ : false;

      if (this.validateErrorTypes(
        this.validationConfiguration[fieldName],
        fieldName,
        (fieldName.includes('[]') ? formData.getAll(fieldName) : formData.get(fieldName))
      )) {
        errorTypes = this.validateErrorTypes(
          this.validationConfiguration[fieldName],
          fieldName,
          (fieldName.includes('[]') ? formData.getAll(fieldName) : formData.get(fieldName))
        );
      }
    });

    // Send
    if (errorCount < 1) {
      if (this.functions.send) {
        this.functions.send(this.el);
      }

      this.el.classList.add('is-loading');

      const caChatValue = getCookie('__ca__chat');

      let data = {
        'action': this.formAction,
        'originUrl': window.location.href,
        'referrer': document.referrer,
        'phoneCountryCode': this.getInternationalTelephoneCountryCode()
      };

      if (caChatValue !== null) {
        data.caChat = caChatValue;
      } else {
        data.caChat = '';
      }

      Array.from(formData.keys()).forEach(key => {
        if (key.includes('[]')) {
          data[key.slice(0, key.length - 2)] = formData.getAll(key);
        } else {
          data[key] = formData.get(key) === 'on' ? true : formData.get(key);
        }
      });

      const url = this.siteDomain + '/wp-admin/admin-ajax.php';
      const functions = this.functions;
      const el = this.el;

      grecaptcha.ready(function () {
        grecaptcha.execute('6LfGEREqAAAAAFOl--v-D9TdKkJFojFsaWzQI88e', { action: 'submit' }).then(function (token) {
          data.token = token;

          axios({
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: url,
            data: qs.stringify(data)
          }).then(response => {
            if (response.data.success == false) {
              if (functions.error) {
                functions.error(el, errorTypes, response.data);
              }
            } else {
              if (functions.success) {
                functions.success(el, response.data);

                let version = '';

                if (el.classList.contains('js-form-v1')) {
                  version = 'v1';
                } else if (el.classList.contains('js-form-v2')) {
                  version = 'v2';
                }

                let userClientUpdateData = {};
                let userEventData = {};

                if (data.email) {
                  userClientUpdateData.email = data.email;
                }

                if (data.phone) {
                  userClientUpdateData.phone_number = data.phone;
                  userEventData['Phone number'] = data.phone;
                }

                if (data.name) {
                  userClientUpdateData.name = data.name;
                  userEventData['Name'] = data.name;
                }

                if (data.company) {
                  userClientUpdateData.company = data.company;
                  userEventData['Company'] = data.company;
                }

                if (data.currentUrl) {
                  userEventData['URL'] = data.currentUrl;
                }

                if (data.dark_funnel_1) {
                  userEventData['Dark funnel 1'] = data.dark_funnel_1;

                  if (data.dark_funnel_1_custom_text_option) {
                    userEventData['Dark funnel 1'] = data.dark_funnel_1_custom_text_option;
                  }
                }

                if (data.dark_funnel_2) {
                  userEventData['Dark funnel 2'] = data.dark_funnel_2;

                  if (data.dark_funnel_2_custom_text_option) {
                    userEventData['Dark funnel 2'] = data.dark_funnel_2_custom_text_option;
                  }
                }

                if (data.dark_funnel_3) {
                  userEventData['Dark funnel 3'] = data.dark_funnel_3;

                  if (data.dark_funnel_3_custom_text_option) {
                    userEventData['Dark funnel 3'] = data.dark_funnel_3_custom_text_option;
                  }
                }

                if (data.dark_funnel_4) {
                  userEventData['Dark funnel 4'] = data.dark_funnel_4;

                  if (data.dark_funnel_4_custom_text_option) {
                    userEventData['Dark funnel 4'] = data.dark_funnel_4_custom_text_option;
                  }
                }

                if (data.dark_funnel_5) {
                  userEventData['Dark funnel 5'] = data.dark_funnel_5;

                  if (data.dark_funnel_2_custom_text_option) {
                    userEventData['Dark funnel 5'] = data.dark_funnel_5_custom_text_option;
                  }
                }

                if (data.rodo && data.rodo === true) {
                  userEventData['Agreement'] = true;
                } else {
                  userEventData['Agreement'] = false;
                }

                if (data.rodo && data.rodo === true) {
                  if (version == 'v1') {
                    if (typeof userengage !== 'undefined') {
                      if (data.email) {
                        userEventData['Email'] = data.email;
                      }

                      userengage('event.demo_form_old', userEventData);
                      userengage('client.update', userClientUpdateData);
                    }
                  } else if (version == 'v2') {
                    if (typeof userengage !== 'undefined') {
                      if (data.email) {
                        userEventData['Demo form'] = data.email;
                      }

                      userengage('event.demo_form', userEventData);
                      userengage('client.update', userClientUpdateData);
                    }
                  }
                }

                if (form) {
                  form.reset();
                }
              }
            }
          }).catch(error => {
            console.log('error');
          });
        });
      });
    } else {
      if (this.functions.error) {
        this.functions.error(this.el, errorTypes);
      }
    }
  }

  updateValidationConfiguration(newConfiguration) {
    this.validationConfiguration = newConfiguration;

    this.registerEventListenersForFormFields();
  }
}

const genericEmailList = ['gmail.com', 'icloud.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'hotmail.co.uk', 'hotmail.fr', 'msn.com', 'yahoo.fr', 'wanadoo.fr', 'orange.fr', 'comcast.net', 'yahoo.co.uk', 'yahoo.com.br', 'yahoo.co.in', 'live.com', 'rediffmail.com', 'free.fr', 'gmx.de', 'web.de', 'yandex.ru', 'ymail.com', 'libero.it', 'outlook.com', 'uol.com.br', 'bol.com.br', 'mail.ru', 'cox.net', 'hotmail.it', 'sbcglobal.net', 'sfr.fr', 'live.fr', 'verizon.net', 'live.co.uk', 'googlemail.com', 'yahoo.es', 'ig.com.br', 'live.nl', 'bigpond.com', 'terra.com.br', 'yahoo.it', 'neuf.fr', 'yahoo.de', 'alice.it', 'rocketmail.com', 'att.net', 'laposte.net', 'facebook.com', 'bellsouth.net', 'yahoo.in', 'hotmail.es', 'charter.net', 'yahoo.ca', 'yahoo.com.au', 'rambler.ru', 'hotmail.de', 'tiscali.it', 'shaw.ca', 'yahoo.co.jp', 'sky.com', 'earthlink.net', 'optonline.net', 'freenet.de', 't-online.de', 'aliceadsl.fr', 'virgilio.it', 'home.nl', 'qq.com', 'telenet.be', 'me.com', 'yahoo.com.ar', 'tiscali.co.uk', 'yahoo.com.mx', 'voila.fr', 'gmx.net', 'mail.com', 'planet.nl', 'tin.it', 'live.it', 'ntlworld.com', 'arcor.de', 'yahoo.co.id', 'frontiernet.net', 'hetnet.nl', 'live.com.au', 'yahoo.com.sg', 'zonnet.nl', 'club-internet.fr', 'juno.com', 'optusnet.com.au', 'blueyonder.co.uk', 'bluewin.ch', 'skynet.be', 'sympatico.ca', 'windstream.net', 'mac.com', 'centurytel.net', 'chello.nl', 'live.ca', 'aim.com', 'bigpond.net.au', 'wp.pl', 'onet.pl'];

export const formValidators = {
  requiredText: {
    validate: (value, fieldName) => {
      const field = document.querySelector(`[data-field-name="${fieldName}"] [name="${fieldName}"]`);

      if (field) {
        const isFieldRequired = field.getAttribute('aria-required') === 'true';

        if (isFieldRequired) {
          if (value) {
            return value.trim().length;
          }
        } else {
          return true;
        }
      } else {
        if (value) {
          return value.trim().length;
        }
      }
    },
  },
  email: {
    validate: (email) => {
      return email.includes('@');
    },
    isGenericEmail: (email) => {
      let isValid = true;
      let language = 'en';

      const requestDemoForm = document.querySelector('.js-request-demo-form');
      if (requestDemoForm && requestDemoForm.dataset.language) {
        language = requestDemoForm.dataset.language;
      }

      if (language == 'en') {
        genericEmailList.forEach(genericEmail => {
          if (email.includes(genericEmail)) {
            isValid = false;
          }
        });
      }

      return isValid;
    }
  },
  consent: {
    validate: (value) => {
      return !!value;
    },
  },
  valueLength: {
    validate: (value) => {
      return value && value.length;
    },
  }
};

export const defaultValidationConfiguration = {
  email: {
    validators: [
      formValidators.requiredText,
      formValidators.email
    ],
  },
  phone: {
    validators: [
      formValidators.requiredText,
    ],
  },
  name: {
    validators: [
      formValidators.requiredText,
    ],
  },
  company: {
    validators: [
      formValidators.requiredText,
    ],
  },
  dark_funnel_1: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_2: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_3: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_4: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_5: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  preferred_form_of_contact: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  rodo: {
    validators: [formValidators.consent],
    triggerEvent: 'field-change'
  },
  message: {
    validators: [
      formValidators.requiredText,
    ],
  },
};

export const ctaFormValidationConfiguration = {
  email: {
    validators: [
      formValidators.requiredText,
      formValidators.email
    ],
  },
  dark_funnel_1: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_2: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_3: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_4: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_5: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  preferred_form_of_contact: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  rodo: {
    validators: [formValidators.consent],
    triggerEvent: 'field-change'
  },
};

export const downloadSectionValidationConfiguration = {
  email: {
    validators: [
      formValidators.requiredText,
      formValidators.email
    ],
  },
  dark_funnel_1: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_2: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_3: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_4: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_5: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  preferred_form_of_contact: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  rodo: {
    validators: [formValidators.consent],
    triggerEvent: 'field-change'
  },
};

export const newsletterFormValidationConfiguration = {
  email: {
    validators: [
      formValidators.requiredText,
      formValidators.email
    ],
  },
  dark_funnel_1: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_2: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_3: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_4: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_5: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  preferred_form_of_contact: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  rodo: {
    validators: [formValidators.consent],
    triggerEvent: 'field-change'
  },
};

export const landingPageNewsletterFormValidationConfiguration = {
  email: {
    validators: [
      formValidators.requiredText,
      formValidators.email
    ],
  },
  dark_funnel_1: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_2: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_3: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_4: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  dark_funnel_5: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  preferred_form_of_contact: { validators: [formValidators.requiredText,], triggerEvent: 'change', },
  rodo: {
    validators: [formValidators.consent],
    triggerEvent: 'field-change'
  },
};

export const defaultFunctions = {
  send: (el) => {
    const button = el.querySelector('.js-form__button');
    button.classList.add('is-loading');
    button.disabled = true;
    const successMessageElement = el.querySelector('.js-form__notification');
    successMessageElement.classList
      .remove('is-active');
  },
  success: (el, responseData) => {
    const language = el.dataset.language;
    const thankYouPage = document.querySelector('.js-form input[name="thank-you-page"]');

    if (thankYouPage) {
      window.location.href = thankYouPage.value;
    } else {
      const successMessageElement = el.querySelector('.js-form__notification');
      successMessageElement.classList
        .remove('is-error');
      successMessageElement.classList
        .add('is-active', 'is-success');

      if (language === 'pl') {
        const preferredFormOfContact = el.querySelector('select[name="preferred_form_of_contact"]');
        if (preferredFormOfContact) {
          const preferredFormOfContactValue = preferredFormOfContact ? preferredFormOfContact.value : '';

          successMessageElement.innerHTML = `Dziękujemy. Twoja wiadomość została wysłana. Nasz zespół skontaktuje się Tobą ${preferredFormOfContactValue}`;
        } else {
          successMessageElement.innerHTML = 'Twoja wiadomość została wysłana. Dziękujemy.';
        }
      } else if (language === 'de') {
        successMessageElement.innerHTML = 'Ihre Nachricht wurde gesendet. Vielen Dank.';
      } else if (language === 'es') {
        successMessageElement.innerHTML = 'Tu mensaje ha sido enviado. Gracias.';
      } else if (language === 'fr') {
        successMessageElement.innerHTML = 'Votre message a été envoyé. Nous vous en remercions.';
      } else {
        successMessageElement.innerHTML = 'Thank you! We’ll be in touch shortly';
      }

      const button = el.querySelector('.js-form__button');
      button.classList.remove('is-loading');
      button.disabled = false;
    }
  },
  error: (el, errorTypes, response) => {
    const language = el.dataset.language;

    let responseDataField = null;
    let responseDataMessage = null;

    if (response) {
      const responseData = response.data;
      if (responseData) {
        if (language === 'pl') {
          responseDataField = responseData.pl.field;
          responseDataMessage = responseData.pl.message;
        } else {
          responseDataField = responseData.en.field;
          responseDataMessage = responseData.en.message;
        }
      }
    }

    let firstField = el.querySelector('.has-error[data-field-name]');

    if (!firstField && responseDataField) {
      firstField = el.querySelector(`.js-input[data-field-name="${responseDataField}"]`);

      if (firstField) {
        firstField.classList.add('has-error');
      }
    }

    if (firstField && !isElementInViewport(firstField)) {
      const headerOffsetHeight = document.querySelector('.js-header').offsetHeight;
      const bodyOffset = document.body.getBoundingClientRect();
      ScrollToSection.scroll(firstField.getBoundingClientRect().top - bodyOffset.top - headerOffsetHeight);
    }

    const errorMessageElement = el.querySelector('.js-form__notification');
    errorMessageElement.classList
      .remove('is-success');
    errorMessageElement.classList
      .add('is-active', 'is-error');

    if (responseDataMessage) {
      errorMessageElement.innerHTML = responseDataMessage;
    } else {
      if (errorTypes.length > 0 && errorTypes.includes('generic-email')) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Użyj firmowego adresu email.';
        } else {
          errorMessageElement.innerHTML = 'Please use a business email.';
        }
      } else {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Pola są błędnie wypełnione. Sprawdź i spróbuj ponownie.';
        } else if (language === 'de') {
          errorMessageElement.innerHTML = 'Die Felder sind nicht korrekt ausgefüllt. Bitte überprüfen Sie dies und versuchen Sie es erneut.';
        } else if (language === 'es') {
          errorMessageElement.innerHTML = 'Los campos han sido completados erróneamente. Compruébalo e inténtalo de nuevo.';
        } else if (language === 'fr') {
          errorMessageElement.innerHTML = 'Les champs ont été complétés de manière incorrecte. Vérifiez et essayez de nouveau.';
        } else {
          errorMessageElement.innerHTML = 'One or more fields have an error. \n Please check and try again.';
        }
      }
    }

    const button = el.querySelector('.js-form__button');
    button.classList.remove('is-loading');
    button.disabled = false;
  }
};

export const livestormFunctions = {
  send: (el) => {
    const button = el.querySelector('.js-form__button');
    button.classList.add('is-loading');
    button.disabled = true;
    const successMessageElement = el.querySelector('.js-form__notification');
    successMessageElement.classList
      .remove('is-active');
  },
  success: (el, responseData) => {
    const language = el.dataset.language;
    const thankYouPage = document.querySelector('.js-form input[name="thank-you-page"]');

    if (thankYouPage) {
      window.location.href = thankYouPage.value;
    } else {
      const successMessageElement = el.querySelector('.js-form__notification');
      successMessageElement.classList
        .remove('is-error');
      successMessageElement.classList
        .add('is-active', 'is-success');

      successMessageElement.innerHTML = 'Dziękujemy za zapisanie się na webinar! Niebawem dostaniesz od na maila ze wszystkimi szczegółami!';

      const button = el.querySelector('.js-form__button');
      button.classList.remove('is-loading');
      button.disabled = false;
    }
  },
  error: (el, errorTypes, response) => {
    const language = el.dataset.language;

    let responseDataField = null;
    let responseDataMessage = null;

    if (response) {
      const responseData = response.data;
      if (responseData) {
        if (language === 'pl') {
          responseDataField = responseData.pl.field;
          responseDataMessage = responseData.pl.message;
        } else {
          responseDataField = responseData.en.field;
          responseDataMessage = responseData.en.message;
        }
      }
    }

    let firstField = el.querySelector('.has-error[data-field-name]');

    if (!firstField && responseDataField) {
      firstField = el.querySelector(`.js-input[data-field-name="${responseDataField}"]`);

      if (firstField) {
        firstField.classList.add('has-error');
      }
    }

    if (firstField && !isElementInViewport(firstField)) {
      const headerOffsetHeight = document.querySelector('.js-header').offsetHeight;
      const bodyOffset = document.body.getBoundingClientRect();
      ScrollToSection.scroll(firstField.getBoundingClientRect().top - bodyOffset.top - headerOffsetHeight);
    }

    const errorMessageElement = el.querySelector('.js-form__notification');
    errorMessageElement.classList
      .remove('is-success');
    errorMessageElement.classList
      .add('is-active', 'is-error');

    if (responseDataMessage) {
      errorMessageElement.innerHTML = responseDataMessage;
    } else {
      if (errorTypes.length > 0 && errorTypes.includes('generic-email')) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Użyj firmowego adresu email.';
        } else {
          errorMessageElement.innerHTML = 'Please use a business email.';
        }
      } else {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Pola są błędnie wypełnione. Sprawdź i spróbuj ponownie.';
        } else if (language === 'de') {
          errorMessageElement.innerHTML = 'Die Felder sind nicht korrekt ausgefüllt. Bitte überprüfen Sie dies und versuchen Sie es erneut.';
        } else if (language === 'es') {
          errorMessageElement.innerHTML = 'Los campos han sido completados erróneamente. Compruébalo e inténtalo de nuevo.';
        } else if (language === 'fr') {
          errorMessageElement.innerHTML = 'Les champs ont été complétés de manière incorrecte. Vérifiez et essayez de nouveau.';
        } else {
          errorMessageElement.innerHTML = 'One or more fields have an error. \n Please check and try again.';
        }
      }
    }

    const button = el.querySelector('.js-form__button');
    button.classList.remove('is-loading');
    button.disabled = false;
  }
};

export const ctaFormFunctions = {
  send: (el) => {
    const button = el.querySelector('.js-form__button');
    button.classList.add('is-loading');
    button.disabled = true;
    const successMessageElement = el.querySelector('.js-form__notification');
    successMessageElement.classList
      .remove('is-active');
  },
  success: (el, responseData) => {
    const language = el.dataset.language;
    const thankYouPage = document.querySelector('.js-cta-form input[name="thank-you-page"]');

    if (thankYouPage) {
      window.location.href = thankYouPage.value;
    } else {
      const successMessageElement = el.querySelector('.js-form__notification');
      successMessageElement.classList
        .remove('is-error');
      successMessageElement.classList
        .add('is-active', 'is-success');

      if (language === 'pl') {
        successMessageElement.innerHTML = responseData.data.pl.message;
      } else {
        successMessageElement.innerHTML = responseData.data.en.message;
      }

      const button = el.querySelector('.js-form__button');
      button.classList.remove('is-loading');
      button.disabled = false;
    }
  },
  error: (el, errorTypes, responseData) => {
    const language = el.dataset.language;
    const errorMessageElement = el.querySelector('.js-form__notification');
    errorMessageElement.classList
      .remove('is-success');
    errorMessageElement.classList
      .add('is-active', 'is-error');

    if (responseData) {
      if (responseData.data) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = responseData.data.pl.message;
        } else {
          errorMessageElement.innerHTML = responseData.data.en.message;
        }
      }
    } else {
      if (errorTypes.length > 0 && errorTypes.includes('generic-email')) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Użyj firmowego adresu email.';
        } else {
          errorMessageElement.innerHTML = 'Please use a business email.';
        }
      } else {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Pola są błędnie wypełnione. Sprawdź i spróbuj ponownie.';
        } else if (language === 'de') {
          errorMessageElement.innerHTML = 'Die Felder sind nicht korrekt ausgefüllt. Bitte überprüfen Sie dies und versuchen Sie es erneut.';
        } else if (language === 'es') {
          errorMessageElement.innerHTML = 'Los campos han sido completados erróneamente. Compruébalo e inténtalo de nuevo.';
        } else if (language === 'fr') {
          errorMessageElement.innerHTML = 'Les champs ont été complétés de manière incorrecte. Vérifiez et essayez de nouveau.';
        } else {
          errorMessageElement.innerHTML = 'One or more fields have an error. \n Please check and try again.';
        }
      }
    }

    const button = el.querySelector('.js-form__button');
    button.classList.remove('is-loading');
    button.disabled = false;
  }
};

export const downloadSectionFunctions = {
  send: (el) => {
    const button = el.querySelector('.js-form__button');
    button.classList.add('is-loading');
    button.disabled = true;
    const successMessageElement = el.querySelector('.js-form__notification');
    successMessageElement.classList
      .remove('is-active');
  },
  success: (el, responseData) => {
    const language = el.dataset.language;
    const thankYouPage = document.querySelector('.js-download-section input[name="thank-you-page"]');

    if (thankYouPage) {
      window.location.href = thankYouPage.value;
    } else {
      const successMessageElement = el.querySelector('.js-form__notification');
      successMessageElement.classList
        .remove('is-error');
      successMessageElement.classList
        .add('is-active', 'is-success');

      if (language === 'pl') {
        successMessageElement.innerHTML = responseData.data.pl.message;
      } else {
        successMessageElement.innerHTML = responseData.data.en.message;
      }

      const button = el.querySelector('.js-form__button');
      button.classList.remove('is-loading');
      button.disabled = false;
    }
  },
  error: (el, errorTypes, responseData) => {
    const language = el.dataset.language;
    const errorMessageElement = el.querySelector('.js-form__notification');
    errorMessageElement.classList
      .remove('is-success');
    errorMessageElement.classList
      .add('is-active', 'is-error');

    if (responseData) {
      if (responseData.data) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = responseData.data.pl.message;
        } else {
          errorMessageElement.innerHTML = responseData.data.en.message;
        }
      }
    } else {
      if (errorTypes.length > 0 && errorTypes.includes('generic-email')) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Użyj firmowego adresu email.';
        } else {
          errorMessageElement.innerHTML = 'Please use a business email.';
        }
      } else {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Pola są błędnie wypełnione. Sprawdź i spróbuj ponownie.';
        } else if (language === 'de') {
          errorMessageElement.innerHTML = 'Die Felder sind nicht korrekt ausgefüllt. Bitte überprüfen Sie dies und versuchen Sie es erneut.';
        } else if (language === 'es') {
          errorMessageElement.innerHTML = 'Los campos han sido completados erróneamente. Compruébalo e inténtalo de nuevo.';
        } else if (language === 'fr') {
          errorMessageElement.innerHTML = 'Les champs ont été complétés de manière incorrecte. Vérifiez et essayez de nouveau.';
        } else {
          errorMessageElement.innerHTML = 'One or more fields have an error. \n Please check and try again.';
        }
      }
    }

    const button = el.querySelector('.js-form__button');
    button.classList.remove('is-loading');
    button.disabled = false;
  }
};

export const newsletterFormFunctions = {
  send: (el) => {
    const button = el.querySelector('.js-form__button');
    button.classList.add('is-loading');
    button.disabled = true;
    const successMessageElement = el.querySelector('.js-form__notification');
    successMessageElement.classList
      .remove('is-active');
  },
  success: (el, responseData) => {
    const language = el.dataset.language;
    const thankYouPage = document.querySelector('.js-newsletter-form input[name="thank-you-page"]');

    if (thankYouPage) {
      window.location.href = thankYouPage.value;
    } else {
      const successMessageElement = el.querySelector('.js-form__notification');
      successMessageElement.classList
        .remove('is-error');
      successMessageElement.classList
        .add('is-active', 'is-success');

      if (language === 'pl') {
        successMessageElement.innerHTML = responseData.data.pl.message;
      } else {
        successMessageElement.innerHTML = responseData.data.en.message;
      }

      const button = el.querySelector('.js-form__button');
      button.classList.remove('is-loading');
      button.disabled = false;
    }
  },
  error: (el, errorTypes, responseData) => {
    const language = el.dataset.language;
    const errorMessageElement = el.querySelector('.js-form__notification');
    errorMessageElement.classList
      .remove('is-success');
    errorMessageElement.classList
      .add('is-active', 'is-error');

    if (responseData) {
      if (responseData.data) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = responseData.data.pl.message;
        } else {
          errorMessageElement.innerHTML = responseData.data.en.message;
        }
      }
    } else {
      if (errorTypes.length > 0 && errorTypes.includes('generic-email')) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Użyj firmowego adresu email.';
        } else {
          errorMessageElement.innerHTML = 'Please use a business email.';
        }
      } else {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Pola są błędnie wypełnione. Sprawdź i spróbuj ponownie.';
        } else if (language === 'de') {
          errorMessageElement.innerHTML = 'Die Felder sind nicht korrekt ausgefüllt. Bitte überprüfen Sie dies und versuchen Sie es erneut.';
        } else if (language === 'es') {
          errorMessageElement.innerHTML = 'Los campos han sido completados erróneamente. Compruébalo e inténtalo de nuevo.';
        } else if (language === 'fr') {
          errorMessageElement.innerHTML = 'Les champs ont été complétés de manière incorrecte. Vérifiez et essayez de nouveau.';
        } else {
          errorMessageElement.innerHTML = 'One or more fields have an error. \n Please check and try again.';
        }
      }
    }

    const button = el.querySelector('.js-form__button');
    button.classList.remove('is-loading');
    button.disabled = false;
  }
};

export const landingPageNewsletterFormFunctions = {
  send: (el) => {
    const button = el.querySelector('.js-form__button');
    button.classList.add('is-loading');
    button.disabled = true;
    const successMessageElement = el.querySelector('.js-form__notification');
    successMessageElement.classList
      .remove('is-active');
  },
  success: (el, responseData) => {
    const language = el.dataset.language;
    const thankYouPage = document.querySelector('.js-landing-page-newsletter-form input[name="thank-you-page"]');

    if (thankYouPage) {
      window.location.href = thankYouPage.value;
    } else {
      const successMessageElement = el.querySelector('.js-form__notification');
      successMessageElement.classList
        .remove('is-error');
      successMessageElement.classList
        .add('is-active', 'is-success');
      if (language === 'pl') {
        successMessageElement.innerHTML = responseData.data.pl.message;
      } else {
        successMessageElement.innerHTML = responseData.data.en.message;
      }
      const button = el.querySelector('.js-form__button');
      button.classList.remove('is-loading');
      button.disabled = false;
    }
  },
  error: (el, errorTypes, responseData) => {
    const language = el.dataset.language;
    const errorMessageElement = el.querySelector('.js-form__notification');
    errorMessageElement.classList
      .remove('is-success');
    errorMessageElement.classList
      .add('is-active', 'is-error');

    if (responseData) {
      if (responseData.data) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = responseData.data.pl.message;
        } else {
          errorMessageElement.innerHTML = responseData.data.en.message;
        }
      }
    } else {
      if (errorTypes.length > 0 && errorTypes.includes('generic-email')) {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Użyj firmowego adresu email.';
        } else {
          errorMessageElement.innerHTML = 'Please use a business email.';
        }
      } else {
        if (language === 'pl') {
          errorMessageElement.innerHTML = 'Pola są błędnie wypełnione. Sprawdź i spróbuj ponownie.';
        } else if (language === 'de') {
          errorMessageElement.innerHTML = 'Die Felder sind nicht korrekt ausgefüllt. Bitte überprüfen Sie dies und versuchen Sie es erneut.';
        } else if (language === 'es') {
          errorMessageElement.innerHTML = 'Los campos han sido completados erróneamente. Compruébalo e inténtalo de nuevo.';
        } else if (language === 'fr') {
          errorMessageElement.innerHTML = 'Les champs ont été complétés de manière incorrecte. Vérifiez et essayez de nouveau.';
        } else {
          errorMessageElement.innerHTML = 'One or more fields have an error. \n Please check and try again.';
        }
      }
    }

    const button = el.querySelector('.js-form__button');
    button.classList.remove('is-loading');
    button.disabled = false;
  }
};

const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

function getCookie(name) {
  const cookies = document.cookie.split(';');

  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split('=');

    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }

  return null;
}

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector('.js-form');
  if (el) {
    const livestormSessionId = el.dataset.livestormSessionId;
    if (livestormSessionId) {
      new Form(el, 'livestormReceiver', defaultValidationConfiguration, livestormFunctions);
    } else {
      new Form(el, 'leadReceiver', defaultValidationConfiguration, defaultFunctions);
    }
  }

  const ctaForm = document.querySelector('.js-cta-form');
  if (ctaForm) {
    new Form(ctaForm, 'emailReceiver', ctaFormValidationConfiguration, ctaFormFunctions);
  }

  const downloadSection = document.querySelector('.js-download-section');
  if (downloadSection) {
    new Form(downloadSection, 'emailReceiver', downloadSectionValidationConfiguration, downloadSectionFunctions);
  }

  const newsletterForm = document.querySelector('.js-newsletter-form');
  if (newsletterForm) {
    new Form(newsletterForm, 'emailReceiver', newsletterFormValidationConfiguration, newsletterFormFunctions);
  }

  const landingPageNewsletterForm = document.querySelector('.js-landing-page-newsletter-form');
  if (landingPageNewsletterForm) {
    new Form(landingPageNewsletterForm, 'emailReceiver', landingPageNewsletterFormValidationConfiguration, landingPageNewsletterFormFunctions);
  }
});
class Checkbox {
    constructor(el) {
        this.el = el;
        this.input = this.el.querySelector('input');
        this.icon = this.el.querySelector('.a-checkbox__icon');
        this.label = this.el.querySelector('.a-checkbox__label');

        this.icon.addEventListener('click', () => {
            this.toggle();
        })
        this.label.addEventListener('click', () => {
            this.toggle();
        })
    }

    dispatchEvent(name) {
        const event = new CustomEvent(name, {
            detail: {
                label: this.label.innerText,
                value: this.input.checked
            }
        });
        this.el.dispatchEvent(event);
    }

    toggle() {
        let checked = this.el.classList.toggle('is-active');
        if (checked) {
            this.input.checked = true;

        } else {
            this.input.checked = false;
        }
        this.dispatchEvent('field-change');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    let elements = document.querySelectorAll('.a-checkbox');
    elements.forEach((el) => {
        new Checkbox(el);
    })
});

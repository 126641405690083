import Swiper from 'swiper/swiper-bundle';

class TierListSection {
  constructor(element) {
    this.siteHeader = document.querySelector('.js-header');
    this.siteHeaderHamburgers = document.querySelectorAll('.js-header__hamburger');

    this.element = element;
    this.header = element.querySelector('.o-tier-list-section__header');
    this.openButtons = element.querySelectorAll('.js-tier-list-section__col-toggler');
    this.linkButtons = element.querySelectorAll('.js-tier-list-section__link-button');
    this.players = element.querySelectorAll('.js-tier-list-section__player');

    this.setHeaderTopOffset();
    window.addEventListener('resize', this.setHeaderTopOffset);

    this.toggleStickyList();
    window.addEventListener('scroll', this.toggleStickyList);

    this.openButtons.forEach(openButton => {
      openButton.addEventListener('click', this.openPopup);
    });

    if (this.linkButtons) {
      this.linkButtons.forEach(linkButton => {
        linkButton.addEventListener('click', this.saveTierIndexToSessionStorage);
      });
    }

    if (this.players) {
      this.handlePlayers();
    }

    this.initSwiper();

    setTimeout(this.setHeaderTopOffset, 50);
  }

  initSwiper() {
    this.slider = new Swiper(this.element.querySelector('.swiper-container'), {
      slidesPerView: 1,
      resizeObserver: true,
      speed: 500
    });

    const prevButtons = this.element.querySelectorAll('.js-tier-list-section__nav-button--prev');
    const nextButtons = this.element.querySelectorAll('.js-tier-list-section__nav-button--next');

    prevButtons.forEach(prevButton => {
      prevButton.addEventListener('click', () => {
        this.slider.slidePrev();
      });
    });

    nextButtons.forEach(nextButton => {
      nextButton.addEventListener('click', () => {
        this.slider.slideNext();
      });
    });
  }

  setActiveSwiperSlide(slideIndex) {
    this.slider.slideTo(slideIndex, 1)
  }

  toggleStickyList = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 768) {
      const windowOffset = window.pageYOffset;
      const TierListSectionHeaderOffset = this.header.offsetTop;

      if (windowOffset >= TierListSectionHeaderOffset) {
        document.body.style.overflowX = 'initial';
      } else {
        document.body.style.overflowX = 'hidden';
      }
    }
  }

  setHeaderTopOffset = () => {
    const mainHeader = document.querySelector('.js-header');

    if (!mainHeader) return;

    if (mainHeader.classList.contains('is-news-bar')) {
      this.header.style.top = document.querySelector('.js-header').offsetHeight - 24 + 'px';
    } else {
      this.header.style.top = document.querySelector('.js-header').offsetHeight + 'px';
    }
  }

  getPopupsContainer = () => {
    return this.element.querySelector('.o-tier-list-section__popups');
  }

  activePopupsContainer = () => {
    this.getPopupsContainer().classList.add('o-tier-list-section__popups--active');
  }

  deactivePopupsContainer = () => {
    this.getPopupsContainer().classList.remove('o-tier-list-section__popups--active');
  }

  toggleHtmlOverflow = () => {
    document.getElementsByTagName('html')[0].style.overflow = document.getElementsByTagName('html')[0].style.overflow === 'hidden' ? '' : 'hidden';
  }

  closePopup = () => {
    this.element.classList.remove('o-tier-list-section--active');
    this.deactivePopupsContainer();
    this.siteHeader.classList.remove('is-tier-list-popup-active');
    this.siteHeaderHamburgers.forEach(hamburger => {
      if (hamburger.classList.contains('is-tier-list-active')) {
        hamburger.classList.add('js-block-transition');
        setTimeout(function () {
          hamburger.classList.remove('js-block-transition');
        }, 300);
      }
      hamburger.classList.remove('is-tier-list-active');
    });
  }

  handlePlayers() {
    let currentPlaying = null;

    this.players.forEach(player => {
      const play = player.querySelector('.js-tier-list-section__player-icon-play');
      const pause = player.querySelector('.js-tier-list-section__player-icon-pause');
      const audioFile = player.querySelector('.js-tier-list-section__player-file');

      play.addEventListener('click', () => {
        if (currentPlaying) {
          const pause = currentPlaying.querySelector('.js-tier-list-section__player-icon-pause');
          pause.click();
        }

        player.classList.add('is-playing');
        play.classList.remove('is-active');
        pause.classList.add('is-active');

        audioFile.play();
        currentPlaying = player;
      });

      pause.addEventListener('click', () => {
        player.classList.remove('is-playing');
        pause.classList.remove('is-active');
        play.classList.add('is-active');

        audioFile.pause();
        currentPlaying = null;
      });
    });
  }

  openPopup = (event) => {
    const target = event.target;
    const targetIndex = target.dataset.index;
    this.setActiveSwiperSlide(targetIndex);

    this.toggleHtmlOverflow();
    this.element.classList.add('o-tier-list-section--active');
    this.activePopupsContainer();
    this.siteHeader.classList.add('is-tier-list-popup-active');

    this.siteHeaderHamburgers.forEach(hamburger => {
      hamburger.classList.add('is-tier-list-active');
      hamburger.classList.add('is-active');
      hamburger.addEventListener('click', this.closePopup);
    });

    const newsBar = document.querySelector('.js-news-bar');
    if (newsBar) {
      const newsBarHeight = newsBar.offsetHeight;

      const popups = document.querySelectorAll('.o-tier-list-section__popup');
      if (popups) {
        popups.forEach(popup => {
          popup.style.paddingTop = newsBarHeight + 43 + 'px';
        });
      }
    }
  }

  saveTierIndexToSessionStorage = (event) => {
    const target = event.target;
    const targetIndex = target.dataset.index;
    sessionStorage.setItem('tierListIndex', targetIndex);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-tier-list-section');

  if (elements) {
    elements.forEach(element => {
      new TierListSection(element);
    });
  }
});
import Swiper from 'swiper/swiper-bundle';

class VideoSection {
  constructor(section) {
    this.section = section;
    this.swipers = [];

    this.videoHandler();
    this.initVideoSlider();
  }

  videoHandler() {
    this.videoWrappers = this.section.querySelectorAll('.js-video-section__video-wrapper');

    if (!this.videoWrappers) return;

    this.videoWrappers.forEach(videoWrapper => {
      const video = videoWrapper.querySelector('.js-video-section__video');
      if (!video) return;

      const videoPlayButton = videoWrapper.querySelector('.js-video-section__video-play-button');
      if (!videoPlayButton) return;

      this.handleVideoPlayButton(videoPlayButton, videoWrapper, video);
      this.registerVideoEventListeners(video, videoWrapper);
    });
  }

  handleVideoPlayButton(videoPlayButton, videoWrapper, video) {
    videoPlayButton.addEventListener('click', () => {
      videoWrapper.classList.add('is-playing');

      video.play();
      video.controls = true;

      this.updateSwipersAutoHeight();
    });
  }

  registerVideoEventListeners(video, videoWrapper) {
    video.addEventListener('pause', () => {
      videoWrapper.classList.remove('is-playing');

      video.controls = false;
    });

    video.addEventListener('seeking', (event) => {
      videoWrapper.classList.add('is-playing');

      video.play();
      video.controls = true;
    });

    video.addEventListener('seeked', (event) => {
      videoWrapper.classList.add('is-playing');

      video.play();
      video.controls = true;
    });

    // update the swiper auto height on video load
    video.addEventListener('loadeddata', () => {
      this.updateSwipersAutoHeight();
    });
  }

  initVideoSlider() {
    const videoSlider = this.section.querySelector('.js-video-section__video-slider');

    if (!videoSlider) return;

    const swiperContainer = videoSlider.querySelector('.swiper-container');

    if (!swiperContainer) return;

    let swiperOptions = {
      speed: 500,
      autoHeight: true,
    }

    const paginationElement = videoSlider.querySelector('.swiper-pagination');

    if (paginationElement) {
      swiperOptions = {
        ...swiperOptions,
        pagination: {
          el: paginationElement,
          clickable: true
        }
      }
    }

    const swiper = new Swiper(swiperContainer, swiperOptions);

    if (!swiper) return;

    this.swipers.push(swiper);
  }

  updateSwipersAutoHeight() {
    if (!this.swipers) return;

    this.swipers.forEach(swiper => {
      // Check if this swiper instance has the autoHeight property enabled before updating it. If not, return.
      if (!swiper.params.autoHeight) return;

      setTimeout(() => {
        swiper.updateAutoHeight(500);
      }, 100);
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('.js-video-section');

  if (sections) {
    sections.forEach(section => {
      new VideoSection(section);
    });
  }
});
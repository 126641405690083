class Calendar {
  constructor(input, options) {
    this.calendarId = talkie_calendar.calendar_id ?? null;
    this.calendarSection = document.querySelector(`[data-calendarid="${this.calendarId}"]`) ?? null;
    this.eventList = this.calendarSection.querySelector('.js-event-list') ?? null;
    this.language = this.calendarSection.dataset.lang ?? 'en';
    this.now = new Date();
    this.day = this.now.getDate();
    this.month = this.now.getMonth();
    this.year = this.now.getFullYear();
    this.input = input;
    this.divCnt = null;
    this.divTable = null;
    this.divDateText = null;
    this.buttonPrev = null;
    this.buttonNext = null;

    const defaultOptions = {
      onDateSelect: (element, day, month, year) => {
        const monthText = ((month + 1) < 10) ? "0" + (month + 1) : month + 1;
        const dayText = (day < 10) ? "0" + day : day;
        this.input.value = `${dayText}-${monthText}-${this.year}`;

        if (element.dataset.eventid) {
          this.deactiveAllEvents();

          const eventsIds = element.dataset.eventid;
          const splitEventsIds = eventsIds.split(",");

          if (splitEventsIds) {
            let newEvents = [];

            splitEventsIds.forEach(splitEventsId => {
              const events = this.calendarSection.querySelectorAll(`[data-eventid]`);
              if (events) {
                events.forEach(event => {
                  if (event && event.dataset.eventid.includes(splitEventsId)) {
                    newEvents.push(event);
                  }
                });
              }
            });

            if (newEvents) {
              newEvents.forEach(newEvent => {
                newEvent.classList.add("is-active");
              });
            }
          }

          this.scrollToEvent();
        }
      }
    }
    this.options = Object.assign({}, defaultOptions, options);

    this.handleLastEventListMargin();
    this.handleEventListItemClick();
  }

  activeClosestEvent() {
    if (!this.eventList) return;

    const eventListItems = this.eventList.querySelectorAll('.js-event-list-item');

    if (!eventListItems) return;

    let closestEvent = {};

    eventListItems.forEach(eventListItem => {
      let today = new Date();
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      let eventStartDate = new Date(eventListItem.dataset.startdate) ?? null;
      let eventEndDate = new Date(eventListItem.dataset.enddate) ?? null;

      if (!eventStartDate || !eventEndDate) return;

      // skip if event is in the past
      if (eventStartDate < today && eventEndDate < yesterday) return;

      today = Date.parse(today);
      eventStartDate = Date.parse(eventStartDate);

      let diff = today - eventStartDate;
      diff = Math.abs(diff);

      if (!closestEvent.diff) {
        closestEvent = {
          element: eventListItem,
          diff: diff,
        }
      }

      if (closestEvent.diff > diff) {
        closestEvent = {
          element: eventListItem,
          diff: diff,
        }
      }
    });

    closestEvent.element.click();
  }

  deactiveAllEvents() {
    const oldEvents = this.calendarSection.querySelectorAll(`[data-eventid].is-active`);

    if (oldEvents) {
      oldEvents.forEach(oldEvent => {
        oldEvent.classList.remove("is-active");
      });
    }
  }

  getActiveEventsIds() {
    const activeEvents = this.calendarSection.querySelectorAll(`[data-eventid].is-active`);
    let eventsIds = [];

    if (activeEvents) {
      activeEvents.forEach(activeEvent => {
        eventsIds.push(activeEvent.dataset.eventid);
      });
    }

    return eventsIds;
  }

  setActiveEventsIds(eventsIds) {
    if (eventsIds) {
      eventsIds.forEach(eventId => {
        const events = this.calendarSection.querySelectorAll(`[data-eventid]`);
        if (events) {
          events.forEach(event => {
            if (event && event.dataset.eventid.includes(eventId)) {
              event.classList.add("is-active");
            }
          });
        }
      });
    }
  }

  scrollToEvent() {
    if (!this.eventList) return;

    const eventListContainer = this.eventList.querySelector('.m-event-list__items');

    if (!eventListContainer) return;

    const activeEvent = eventListContainer.querySelector('.js-event-list-item.is-active');

    // Mobile and desktop have different scroll behavior
    if (window.innerWidth < 768) {
      eventListContainer.scrollTo({
        top: 0,
        left: activeEvent.offsetLeft - 16,
        behavior: 'smooth'
      });
    } else {
      eventListContainer.scrollTo({
        top: activeEvent.offsetTop - 16,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  handleEventListItemClick() {
    if (!this.eventList) return;

    const eventListItems = this.eventList.querySelectorAll('.js-event-list-item');

    if (!eventListItems) return;

    eventListItems.forEach(eventListItem => {
      eventListItem.addEventListener('click', (evt) => {
        const eventId = eventListItem.dataset.eventid;

        if (!eventId) return;

        this.deactiveAllEvents();

        const events = this.calendarSection.querySelectorAll(`[data-eventid]`);
        if (events) {
          events.forEach(event => {
            if (event && event.dataset.eventid.includes(eventId)) {
              event.classList.add("is-active");
            }
          });
        }

        this.scrollToEvent();
        this.changeMonthAndYear(eventListItem.dataset.startmonth, eventListItem.dataset.startyear);
      });
    });
  }

  handleLastEventListMargin() {
    if (window.innerWidth < 768) return;

    if (!this.eventList) return;

    const eventListItems = this.eventList.querySelectorAll('.js-event-list-item');

    if (!eventListItems) return;

    const lastEventListItem = eventListItems[eventListItems.length - 1];

    if (!lastEventListItem) return;

    const eventListContainer = this.eventList;

    if (!eventListContainer) return;

    const eventListContainerHeight = eventListContainer.offsetHeight;
    const eventListContainerPadding = 16;
    const itemHeight = lastEventListItem.offsetHeight;

    const lastEventListItemMarginBottom = eventListContainerHeight - eventListContainerPadding - itemHeight;

    lastEventListItem.style.marginBottom = `${lastEventListItemMarginBottom}px`;
  }

  createButtons() {
    const buttonPrev = document.createElement("button");
    buttonPrev.innerHTML = '<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m3.214 8 5.903-5.439c.478-.44.513-1.188.08-1.673A1.155 1.155 0 0 0 7.55.808L1.017 6.825a1.596 1.596 0 0 0 0 2.349l6.532 6.018c.477.44 1.215.403 1.648-.081a1.197 1.197 0 0 0-.08-1.673L3.214 8z" fill="#919AAF"/></svg>';
    buttonPrev.type = "button";
    buttonPrev.classList.add("input-prev");
    buttonPrev.classList.add("m-calendar__calendar-prev-month");
    buttonPrev.addEventListener("click", e => {
      this.month--;
      if (this.month < 0) {
        this.month = 11;
        this.year--;
      }
      this.createCalendarTable();
      this.createDateText();
      this.setActiveEventsIds(this.getActiveEventsIds());
    });

    this.buttonPrev = buttonPrev;

    const buttonNext = document.createElement("button");
    buttonNext.classList.add("input-next");
    buttonNext.classList.add("m-calendar__calendar-next-month");
    buttonNext.innerHTML = '<svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.786 8 .883 13.439a1.197 1.197 0 0 0-.08 1.673c.433.484 1.17.52 1.648.08l6.532-6.018a1.595 1.595 0 0 0 0-2.349L2.45.807A1.155 1.155 0 0 0 .803.888a1.197 1.197 0 0 0 .08 1.673L6.786 8z" fill="#919AAF"/></svg>';
    buttonNext.type = "button";
    buttonNext.addEventListener("click", e => {
      this.month++;
      if (this.month > 11) {
        this.month = 0;
        this.year++;
      }
      this.createCalendarTable();
      this.createDateText();
      this.setActiveEventsIds(this.getActiveEventsIds());
    });

    this.buttonNext = buttonNext;
  }

  createDateText() {
    let monthNames = [];

    if (this.language === 'pl') {
      monthNames = ["styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"];
    } else {
      monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    }

    this.divDateText.innerHTML = monthNames[this.month] + " " + this.year;
  }

  changeMonthAndYear(month, year) {
    if (!month || !year) return;

    var year = parseInt(year);
    var month = parseInt(month - 1);

    if (this.year !== year) {
      if (this.year > year) {
        while (this.year !== year) {
          this.buttonPrev.click();
        }
      } else {
        while (this.year !== year) {
          this.buttonNext.click();
        }
      }
    }

    if (this.month !== month) {
      if (this.month > month) {
        while (this.month !== month) {
          this.buttonPrev.click();
        }
      } else {
        while (this.month !== month) {
          this.buttonNext.click();
        }
      }
    }
  }

  createCalendarTable() {
    this.divTable.innerHTML = "";

    const tab = document.createElement("table");
    tab.classList.add("m-calendar__calendar-table");

    const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

    const tempDate = new Date(this.year, this.month, 1);
    let firstMonthDay = tempDate.getDay();
    if (firstMonthDay === 0) {
      firstMonthDay = 7;
    }

    const j = daysInMonth + firstMonthDay - 1;

    if (firstMonthDay - 1 !== 0) {
      tr = document.createElement("tr");
      tab.appendChild(tr);
    }

    for (let i = 0; i < firstMonthDay - 1; i++) {
      const td = document.createElement("td");
      td.innerHTML = "";
      tr.appendChild(td);
    }

    for (let i = firstMonthDay - 1; i < j; i++) {
      if (i % 7 === 0) {
        tr = document.createElement("tr");
        tab.appendChild(tr);
      }

      const td = document.createElement("td");
      td.innerText = i - firstMonthDay + 2;
      td.dayNr = i - firstMonthDay + 2;
      td.classList.add("day");

      if (this.year === this.now.getFullYear() && this.month === this.now.getMonth() && this.day === i - firstMonthDay + 2) {
        td.classList.add("current-day")
      }

      // if this day is sat or sun
      if (i % 7 === 5 || i % 7 === 6) {
        td.classList.add("weekend");
      }

      if (talkie_calendar && talkie_calendar.events) {
        const eventDots = document.createElement("div");
        eventDots.classList.add("m-calendar__calendar-event-dots");
        td.appendChild(eventDots);

        talkie_calendar.events.forEach(event => {
          const date_from = event.date.calendar_from;
          const date_to = event.date.calendar_to;

          const dates = this.getDatesBetween(date_from, date_to);

          dates.forEach(date => {
            if (this.year === date.getFullYear() && this.month === date.getMonth() && td.dayNr === date.getDate()) {
              if (td.dataset.eventid !== undefined) {
                td.dataset.eventid += "," + event.id;
              } else {
                td.dataset.eventid = event.id;
              }

              td.classList.add("has-event");
              eventDots.innerHTML += '<div class="m-calendar__calendar-event-dot"></div>';
            }
          });
        });
      }

      tr.appendChild(td);
    }

    // Get empty days in last week
    const lastWeekDay = new Date(this.year, this.month + 1, 0).getDay();
    const emptyDays = 7 - lastWeekDay;

    // If last week is not full
    if (emptyDays !== 7) {
      for (let i = 0; i < emptyDays; i++) {
        const td = document.createElement("td");

        // Set days
        td.innerText = i + 1;
        td.dayNr = i + 1;

        // Set classes
        td.classList.add("day");
        td.classList.add("empty-day");
        td.classList.add("next-month");

        if (talkie_calendar && talkie_calendar.events) {
          const eventDots = document.createElement("div");
          eventDots.classList.add("m-calendar__calendar-event-dots");
          td.appendChild(eventDots);

          talkie_calendar.events.forEach(event => {
            const date_from = event.date.calendar_from;
            const date_to = event.date.calendar_to;

            const dates = this.getDatesBetween(date_from, date_to);

            dates.forEach(date => {
              if (this.year === date.getFullYear() && this.month + 1 === date.getMonth() && td.dayNr === date.getDate()) {
                if (td.dataset.eventid !== undefined) {
                  td.dataset.eventid += "," + event.id;
                } else {
                  td.dataset.eventid = event.id;
                }

                td.classList.add("has-event");
                eventDots.innerHTML += '<div class="m-calendar__calendar-event-dot"></div>';
              }
            });
          });
        }

        tr.appendChild(td);
      }
    }

    // Get empty days in first week
    const firstWeekDay = new Date(this.year, this.month, 1).getDay();
    const emptyDaysFirstWeek = firstWeekDay - 1;

    // If first week is not full
    if (emptyDaysFirstWeek !== 0) {

      // remove empty days from the beginning of the table
      for (let i = 0; i < emptyDaysFirstWeek; i++) {
        tab.firstChild.firstChild.remove();
      }

      for (let i = 0; i < emptyDaysFirstWeek; i++) {
        const td = document.createElement("td");

        // Set days (from the end of the previous month)
        td.innerText = new Date(this.year, this.month, 0).getDate() - i;
        td.dayNr = new Date(this.year, this.month, 0).getDate() - i;

        // Set classes
        td.classList.add("day");
        td.classList.add("empty-day");
        td.classList.add("prev-month");

        if (talkie_calendar && talkie_calendar.events) {
          const eventDots = document.createElement("div");
          eventDots.classList.add("m-calendar__calendar-event-dots");
          td.appendChild(eventDots);

          talkie_calendar.events.forEach(event => {
            const date_from = event.date.calendar_from;
            const date_to = event.date.calendar_to;

            const dates = this.getDatesBetween(date_from, date_to);

            dates.forEach(date => {
              if (this.year === date.getFullYear() && this.month - 1 === date.getMonth() && td.dayNr === date.getDate()) {
                if (td.dataset.eventid !== undefined) {
                  td.dataset.eventid += "," + event.id;
                } else {
                  td.dataset.eventid = event.id;
                }

                td.classList.add("has-event");
                eventDots.innerHTML += '<div class="m-calendar__calendar-event-dot"></div>';
              }
            });
          });
        }

        // Add empty days to the beginning of the table
        tab.firstChild.insertBefore(td, tab.firstChild.firstChild);
      }
    }

    tab.appendChild(tr);

    this.divTable.appendChild(tab);
  }

  getDatesBetween(date_from, date_to) {
    const dates = [];
    const dateFrom = new Date(date_from);
    const dateTo = new Date(date_to);
    const currentDate = new Date(dateFrom);

    while (currentDate <= dateTo) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  bindTableDaysEvent() {
    this.divTable.addEventListener("click", e => {
      if (e.target.tagName.toLowerCase() === "td" && e.target.classList.contains("day")) {
        this.options.onDateSelect(e.target, e.target.dayNr, this.month + 1, this.year);
      }
    });
  }

  init() {
    this.divCnt = document.createElement("div");
    this.divCnt.classList.add("m-calendar__calendar");

    this.createButtons();

    this.divDateText = document.createElement("div");
    this.divDateText.className = "m-calendar__calendar-header-date-name";
    this.createDateText();

    this.divHeader = document.createElement("div");
    this.divHeader.classList.add("m-calendar__calendar-header");

    this.divHeader.appendChild(this.buttonPrev);
    this.divHeader.appendChild(this.divDateText);
    this.divHeader.appendChild(this.buttonNext);
    this.divCnt.appendChild(this.divHeader);

    this.divTable = document.createElement("div");
    this.divTable.className = "m-calendar__container";
    this.divCnt.appendChild(this.divTable);
    this.createCalendarTable();
    this.bindTableDaysEvent();

    this.calendarWrapper = document.createElement("div");
    this.calendarWrapper.classList.add("m-calendar__input-calendar-container");
    this.input.parentElement.insertBefore(this.calendarWrapper, this.input);
    this.calendarWrapper.appendChild(this.input);
    this.calendarWrapper.appendChild(this.divCnt);

    this.input.classList.add("m-calendar__input-calendar");
    this.input.addEventListener("click", e => e.stopImmediatePropagation());
    this.divCnt.addEventListener("click", e => e.stopImmediatePropagation());

    this.activeClosestEvent();
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-calendar');
  if (elements) {
    elements.forEach(element => {
      const calendar = new Calendar(element);
      calendar.init();
    });
  }
});
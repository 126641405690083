import Swiper from 'swiper/swiper-bundle';

class ImplementationStageTimelineSection {
  constructor(section) {
    this.section = section;

    this.initSwiper();
  }

  initSwiper() {
    const swiperEl = this.section.querySelector('.swiper-container');

    if (!swiperEl) return;

    const swiperOptions = {
      autoHeight: true,
      autoplay: {
        delay: 7500,
      },
    };

    const swiperPagination = this.section.querySelector('.swiper-pagination');
    if (swiperPagination) {
      swiperOptions.pagination = {
        el: swiperPagination,
        clickable: true,
      };
    }

    const swiper = new Swiper(swiperEl, swiperOptions);

    this.handleSwiperAutoplay(swiper, swiperEl);
  }

  handleSwiperAutoplay(swiper, observedElement) {
    if (!swiper.autoplay) return;

    // Start autoplay when section is in view and stop when out of view
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          swiper.autoplay.start();
        } else {
          swiper.autoplay.stop();
        }
      });
    });

    observer.observe(observedElement);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('.js-implementation-stage-timeline-section');
  if (sections) {
    sections.forEach(section => {
      new ImplementationStageTimelineSection(section);
    });
  }
});
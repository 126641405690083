export default class LanguageSwitcher {
  constructor(element) {
    this.element = document.querySelector(element);

    if (this.element) {
      this.mobileCurrent = this.element.querySelector('.js-language-switcher__mobile-current');
      this.mobileOpenButtons = document.querySelectorAll('.js-language-switcher-mobile-open-button');
      this.mobilePopup = this.element.querySelector('.js-language-switcher__mobile-popup');
      this.isMobilePopupActive = false;

      this.handleMobileCurrentClick();
      this.handleMobileOpenButtonsClick();
    }
  }

  handleMobileCurrentClick() {
    if (this.mobileCurrent) {
      this.mobileCurrent.addEventListener('click', () => {
        this.activeMobilePopup();
      });
    }
  }

  handleMobileOpenButtonsClick() {
    if (this.mobileOpenButtons) {
      this.mobileOpenButtons.forEach(mobileOpenButton => {
        mobileOpenButton.addEventListener('click', () => {
          if (this.isMobilePopupActive) {
            this.deactiveMobilePopup();
          } else {
            this.activeMobilePopup();
          }
        });
      });
    }
  }

  activeMobilePopup() {
    if (this.mobilePopup) {
      this.mobilePopup.classList.add('is-active');

      if (this.mobileOpenButtons) {
        this.mobileOpenButtons.forEach(mobileOpenButton => {
          mobileOpenButton.classList.add('is-active');
        });
      }

      this.isMobilePopupActive = true;
    }
  }

  deactiveMobilePopup() {
    if (this.mobilePopup) {
      this.mobilePopup.classList.remove('is-active');

      if (this.mobileOpenButtons) {
        this.mobileOpenButtons.forEach(mobileOpenButton => {
          mobileOpenButton.classList.remove('is-active');
        });
      }

      this.isMobilePopupActive = false;
    }
  }
}
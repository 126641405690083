import { ScrollToSection } from "../../../../../assets/private/js/scrollToSection";

class StepListSection {
  constructor(element) {
    this.element = element;
    this.listObserverElement = document.querySelector('.js-step-list-section-observer');
    this.scrolling = null;
    this.scrollingTimeout = null;

    const sectionsObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (!this.scrolling) {
              this.toggleList(entry.target.id)
            }
          }
        });
      },
      {
        threshold: [0],
        rootMargin: '-50% 0px'
      }
    );

    window.addEventListener('scroll', this.toggleStickyList);

    window.addEventListener('scroll', this.handleScroll);

    const listItemsLinks = Array.from(this.element.querySelectorAll('.js-step-list-section__list-item-link'));
    listItemsLinks.forEach(item => {

      const targetSectionId = item.dataset.sectionId;
      const targetSectionElement = document.getElementById(targetSectionId);
      sectionsObserver.observe(targetSectionElement);

      item.addEventListener('click', (e) => {
        e.preventDefault();
        this.scrolling = true;
        const elHeight = this.element.offsetHeight;
        this.toggleList(targetSectionId);
        const bodyOffset = document.body.getBoundingClientRect();
        const sectionOffset = targetSectionElement.getBoundingClientRect();
        ScrollToSection.scroll(sectionOffset.top - bodyOffset.top - elHeight);
      });

    });

    this.toggleStickyList();

  }

  toggleStickyList = () => {
    const windowOffset = window.pageYOffset;
    const observerOffset = this.listObserverElement.offsetTop;

    const header = document.querySelector('.js-header');
    const headerHeight = header.offsetHeight;

    this.element.style.top = `${headerHeight + 1}px`;

    if (windowOffset + headerHeight >= observerOffset) {
      this.listObserverElement.style.paddingBottom = this.element.offsetHeight + 'px';
      this.element.classList.add("is-active")
    } else {
      this.listObserverElement.style.paddingBottom = '';
      this.element.classList.remove("is-active")
    }
  }

  toggleList = (id) => {

    const item = this.element.querySelector(`.js-step-list-section__list-item-link[data-section-id="${id}"]`);
    const parentItem = item.closest('.js-step-list-section__list-item');
    this.element.style.setProperty('--list-item-width', parentItem.offsetWidth + 'px');
    this.element.style.setProperty('--current-list-item-position', parentItem.offsetLeft + 'px');
    const currentActiveItem = this.element.querySelector('.js-step-list-section__list-item.is-active');

    if (currentActiveItem && currentActiveItem != parentItem) {
      currentActiveItem.classList.remove('is-active');
    }

    parentItem.classList.add('is-active');

  }

  handleScroll = () => {

    window.clearTimeout(this.scrollingTimeout);

    this.scrollingTimeout = setTimeout(() => {

      if (this.scrolling) {
        this.scrolling = null;
      }

    }, 66);
  }

}

document.addEventListener('DOMContentLoaded', () => {
  const elements = Array.from(document.querySelectorAll('.js-step-list-section'));

  elements.forEach(element => {
    new StepListSection(element);
  })
});
class TierListSectionPopup {
  constructor(element) {
    this.element = element;
    this.index = element.dataset.index;
    this.openButtons = document.querySelectorAll(`.js-tier-list-section__details[data-index="${this.index}"]`);
    this.closeButtons = element.querySelectorAll('.js-tier-list-section-popup__close');

    this.handleOpenButtons();
    this.handleCloseButtons();
  }

  active() {
    this.element.classList.add('is-active');
  }

  deactive() {
    this.element.classList.remove('is-active');
  }

  handleOpenButtons() {
    if (!this.openButtons) return;

    // Open on button click
    this.openButtons.forEach(openButton => {
      openButton.addEventListener('click', () => {
        this.active();
      });
    });
  }

  handleCloseButtons() {
    // Close on background click
    this.element.addEventListener('click', (event) => {
      if (event.target === this.element) {
        this.deactive();
      }
    });

    if (!this.closeButtons) return;

    // Close on button click
    this.closeButtons.forEach(closeButton => {
      closeButton.addEventListener('click', () => {
        this.deactive();
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-tier-list-section-popup');

  if (elements) {
    // Initialize
    elements.forEach(element => {
      new TierListSectionPopup(element);
    });

    // Close on escape click
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        elements.forEach(element => {
          new TierListSectionPopup(element).deactive();
        });
      }
    });
  }
});
class CookiebotToggler {
  constructor(togglers) {
    this.togglers = togglers;
    this.debugMode = window.location.href.includes('cookiebot-toggler-debug');

    this.getElements();
  }

  getElements() {
    this.cookiebot = this.findCookiebot();

    if (this.cookiebot) {
      this.cookiebotToggler = this.findCookiebotToggler(this.cookiebot);
      this.registerEvents();
    } else {
      this.waitForCookiebot();
    }
  }

  findCookiebot() {
    return document.getElementById('CookiebotWidget');
  }

  findCookiebotToggler(cookiebot) {
    return cookiebot.querySelector('.CookiebotWidget-logo');
  }

  waitForCookiebot() {
    let attempts = 1000;
    let currentAttempt = 0;

    const interval = setInterval(() => {
      this.debugLog('Attempting to find Cookiebot');

      this.cookiebot = this.findCookiebot();

      if (this.cookiebot) {
        this.debugLog('Found Cookiebot');
        this.cookiebotToggler = this.findCookiebotToggler(this.cookiebot);
        this.registerEvents();
        clearInterval(interval);
      } else if (++currentAttempt >= attempts) {
        this.debugLog('Could not find Cookiebot');
        clearInterval(interval);
      }
    }, 1000);
  }

  registerEvents() {
    this.togglers.forEach(toggler => {
      this.addClickListener(toggler, () => this.toggleCookiebot());
    });
  }

  addClickListener(element, handler) {
    element.addEventListener('click', handler);
  }

  toggleCookiebot() {
    this.triggerClick(this.cookiebotToggler);
  }

  triggerClick(element) {
    element.click();
  }

  debugLog(message) {
    if (this.debugMode) {
      console.log(`CookiebotToggler: ${message}`);
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const togglers = document.querySelectorAll('.js-cookiebot-toggler');

  if (togglers) {
    new CookiebotToggler(togglers);
  }
});
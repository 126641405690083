class Textarea {
  constructor(el) {
    this.el = el;
    this.textarea = this.el.querySelector('.js-textarea__field');
    this.setRows(this.textarea);

    this.textarea.addEventListener('blur', (e) => {
      this.dispatchEvent('field-blur', e.target.value);
    });
    this.textarea.addEventListener('change', (e) => {
      this.dispatchEvent('field-change', e.target.value);
    });
    this.textarea.addEventListener('keyup', () => this.heightAdjust());
  }

  dispatchEvent(name, value) {
    const event = new CustomEvent(name, {
      detail: {
        value: value
      }
    });
    this.el.dispatchEvent(event);
  }

  setRows(el) {
    if (this.isMobile()) {
      const rows = el.getAttribute('data-rows-mobile');
      if (rows) {
        el.setAttribute('rows', rows - 1);
      }
    } else {
      const rows = el.getAttribute('data-rows-desktop');
      if (rows) {
        el.setAttribute('rows', rows - 1);
      }
    }

    if (this.isMobile() === false) {
      this.heightAdjust();
    }
  }

  heightAdjust() {
    this.textarea.style.height = 'auto';
    this.textarea.style.height = this.textarea.scrollHeight + 18 + 'px';
  }

  isMobile() {
    return window.innerWidth <= 768;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-textarea');
  elements.forEach((el) => {
    new Textarea(el);
  });
});

import Swiper from 'swiper/swiper-bundle';

class TwoColumnsSection {
  constructor(section) {
    this.section = section;

    this.initImageSlider();
  }

  initImageSlider() {
    const imageSlider = this.section.querySelector('.js-two-columns-section__image-slider');

    if (!imageSlider) return;

    const swiperContainer = imageSlider.querySelector('.swiper-container');

    if (!swiperContainer) return;

    let swiperOptions = {
      speed: 500,
      autoHeight: true,
    }

    const paginationElement = imageSlider.querySelector('.swiper-pagination');

    if (paginationElement) {
      swiperOptions = {
        ...swiperOptions,
        pagination: {
          el: paginationElement,
          clickable: true
        }
      }
    }

    new Swiper(swiperContainer, swiperOptions);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('.js-two-columns-section');

  if (sections) {
    sections.forEach(section => {
      new TwoColumnsSection(section);
    });
  }
});
class Breadcrumbs {
  constructor(element) {
    this.element = element;

    if (this.isMobile() === false) {
      this.handleWidows();
    }
  }

  handleWidows() {
    const texts = this.element.querySelectorAll('.js-widow');
    if (texts) {
      texts.forEach(text => {
        let wordArray = text.textContent.split(' ');
        if (wordArray.length > 1) {
          for (let i = 0; i < wordArray.length; i++) {
            if (wordArray[i].length <= 2 && isNaN(wordArray[i]) && i !== wordArray.length - 1) {
              wordArray[i] = '<br>' + wordArray[i];
            }
          }

          let newWord = wordArray.join(' ');
          text.innerHTML = newWord;
        }

        const lines = text.innerHTML.split(' <br>');
        if (lines) {
          const breakLines = text.querySelectorAll('br');
          let index = 0;

          lines.forEach(line => {
            const wordArray = line.split(' ');

            if (wordArray.length <= 1) {
              if (breakLines[index]) {
                breakLines[index].remove();
              }
            }

            index++;
          });
        }
      });
    }
  }

  isMobile() {
    return window.innerWidth <= 768;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-breadcrumbs');

  if (elements) {
    elements.forEach(element => {
      new Breadcrumbs(element);
    });
  }
});
class SearchForm {
    constructor(element) {
        this.element = element;
        this.activeButton = this.element.querySelector('.m-search-form__icon--search');
        this.closeButton = this.element.querySelector('.m-search-form__icon--close');
        this.categoriesWrapper = document.querySelector('.o-blog-nav__categories-wrapper');

        if (this.activeButton) {
            this.activeButton.addEventListener('click', () => this.active());
        }

        if (this.closeButton) {
            this.closeButton.addEventListener('click', () => this.deactive());
        }

        if (this.isMobile()) {
            if (this.categoriesWrapper && this.activeButton && this.closeButton) {
                this.activeButton.addEventListener('click', () => this.deactiveCategories());
                this.closeButton.addEventListener('click', () => this.activeCategories());
            }
        }
    }

    active() {
        this.element.classList.add('is-active');
    }

    deactive() {
        this.element.classList.remove('is-active');
    }

    activeCategories() {
        this.categoriesWrapper.classList.remove('is-hidden');
    }

    deactiveCategories() {
        this.categoriesWrapper.classList.add('is-hidden');
    }

    isMobile() {
        return window.innerWidth <= 768;
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const element = document.querySelector('.js-search-form');
    if (element) {
        new SearchForm(element);
    }
});
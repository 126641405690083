import Swiper from 'swiper/swiper-bundle';

class Slider {
  constructor(element) {
    const nextButton = element.querySelector('.js-slider__button--next');
    const prevButton = element.querySelector('.js-slider__button--prev');
    const pagination = element.querySelector('.js-slider__pagination');

    this.slider = new Swiper(element.querySelector('.swiper-container'), {
      spaceBetween: 24,
      slidesPerView: 1.5,
      resizeObserver: true,
      loop: true,
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
      pagination: {
        el: pagination,
        clickable: true
      },
      speed: 500,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
          slideToClickedSlide: false,
        },
      },
    });

    const paginationItems = Array.from(pagination.querySelectorAll('.swiper-pagination-bullet'));

    const paginationItemWidth = 100 / paginationItems.length;

    paginationItems.forEach(item => {
      item.style.maxWidth = `${paginationItemWidth}%`;
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const sliderElements = Array.from(document.querySelectorAll('.js-slider'));

  if (sliderElements) {
    sliderElements.forEach(element => {
      new Slider(element);
    });
  }
});
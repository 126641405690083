import Swiper from 'swiper/swiper-bundle';

export default class TestimonialSection {
  constructor(element, prevButton = null, nextButton = null, pagination = null) {
    this.element = element;

    if (prevButton && nextButton && pagination) {
      this.initSwiperPrevNext(prevButton, nextButton, pagination);
    } else {
      this.initSwiper();
    }
  }

  initSwiper() {
    const id = this.element.dataset.id;
    const swiperElement = this.element.querySelector('.swiper-container');
    const paginationElement = this.element.querySelector('.swiper-pagination');

    if (!id || !swiperElement) return;

    this.slider = new Swiper(swiperElement, {
      speed: 500,
      navigation: {
        nextEl: '.js-testimonial-section[data-id="' + id + '"] .swiper-button-next',
        prevEl: '.js-testimonial-section[data-id="' + id + '"] .swiper-button-prev',
      },
      pagination: {
        el: paginationElement,
        clickable: true
      },
    });
  }

  initSwiperPrevNext(prevButton, nextButton, pagination) {
    const id = this.element.dataset.id;
    const swiperElement = this.element.querySelector('.swiper-container');

    if (!id || !swiperElement) return;

    this.slider = new Swiper(swiperElement, {
      speed: 500,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
      pagination: {
        el: pagination,
        clickable: true
      },
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-testimonial-section');

  if (elements) {
    elements.forEach(element => {
      new TestimonialSection(element);
    });
  }
});
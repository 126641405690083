class BlogNav {
  constructor(element) {
    this.element = element;
    this.isBackdrop = false;
    this.expandMenuButton = element.querySelector('.js-blog-nav__categories-expand');
    this.expandSubMenusButtons = element.querySelectorAll('.js-blog-nav__expand-submenu');

    if (this.expandMenuButton) {
      this.expandMenuButton.addEventListener('click', () => this.toggle(this.element));
      this.expandMenuButton.addEventListener('click', () => this.handleBackdrop());
    }

    if (this.expandSubMenusButtons) {
      this.expandSubMenusButtons.forEach(subMenuButton => {
        subMenuButton.addEventListener('click', () => this.toggle(subMenuButton));
      });
    }
  }

  toggle(el) {
    el.classList.toggle('is-expanded');
  }

  isExpanded(el) {
    if (el.classList.contains('is-expanded')) {
      return true;
    }

    return false;
  }

  handleBackdrop() {
    const layoutWrapperElement = document.querySelector('.l-wrapper__main');

    if (layoutWrapperElement) {
      if (this.isBackdrop) {
        layoutWrapperElement.classList.remove('is-backdrop');
        this.isBackdrop = false;
      } else {
        layoutWrapperElement.classList.add('is-backdrop');
        this.isBackdrop = true;

        layoutWrapperElement.addEventListener('click', () => {
          if (this.isExpanded(this.element)) {
            layoutWrapperElement.classList.remove('is-backdrop');
            this.toggle(this.element);
            this.isBackdrop = false;
          }
        });
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelector('.js-blog-nav');
  if (element) {
    new BlogNav(element);
  }
});
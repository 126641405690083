class LogoSectionV2 {
  constructor(section) {
    this.section = section;

    this.handleGroups();
  }

  handleGroups() {
    this.groups = this.section.querySelectorAll('.js-logo-section-v2__group');

    if (!this.groups) {
      return;
    }

    this.groups.forEach(group => {
      this.handleGroupDropdownButtons(group);
    });
  }

  handleGroupDropdownButtons(group) {
    const dropdownButtons = group.querySelectorAll('.js-logo-section-v2__group-items-dropdown');

    if (!dropdownButtons) {
      return;
    }

    dropdownButtons.forEach(dropdownButton => {
      this.handleDropdownButtonClick(dropdownButton, group);
      this.handleDropdownButtonVisibility(group, dropdownButton);
    });
  }

  handleDropdownButtonClick(dropdownButton, group) {
    dropdownButton.addEventListener('click', () => {
      this.toggleOpenGroup(group);
    });
  }

  toggleOpenGroup(group) {
    group.classList.toggle('is-open');
  }

  handleDropdownButtonVisibility(group, dropdownButton) {
    let groupItemsContainer = group.querySelector('.js-logo-section-v2__group-items');

    if (!groupItemsContainer) {
      return;
    }

    this.toggleOpenGroup(group);

    // Get height of the container
    const containerHeight = groupItemsContainer.clientHeight;

    this.toggleOpenGroup(group);

    if (this.isMobile()) {
      if (containerHeight > 160) {
        dropdownButton.classList.add('is-visible');
      }
    } else {
      if (containerHeight > 96) {
        dropdownButton.classList.add('is-visible');
      }
    }
  }

  isMobile() {
    return window.innerWidth <= 767;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('.js-logo-section-v2');
  if (sections) {
    sections.forEach(section => {
      new LogoSectionV2(section);
    });
  }
});
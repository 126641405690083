import Swiper from 'swiper/swiper-bundle';
import { ScrollToSection } from "../../../../../assets/private/js/scrollToSection";

class TypesOfFacilitiesSection {
  constructor(element) {
    this.element = element;
    this.navItems = element.querySelectorAll('.js-types-of-facilities-section__nav-item');
    this.contentItems = element.querySelectorAll('.js-types-of-facilities-section__content');

    this.handleNavItems();
    this.handleContentItems();
  }

  handleNavItems() {
    if (!this.navItems) return;

    this.navItems.forEach(navItem => {
      this.handleNavItem(navItem);
    });
  }

  handleNavItem(navItem) {
    if (!navItem) return;

    this.handleNavItemClick(navItem);
  }

  handleNavItemClick(navItem) {
    if (!navItem) return;

    navItem.addEventListener('click', () => {
      const index = navItem.dataset.index;
      if (!index) return;

      const targetContent = this.element.querySelector(`.js-types-of-facilities-section__content[data-index="${index}"]`);
      if (!targetContent) return;

      const activeContent = this.element.querySelector('.js-types-of-facilities-section__content.is-active');
      if (activeContent) {
        activeContent.classList.remove('is-active');
      }

      const activeNavItem = this.element.querySelector('.js-types-of-facilities-section__nav-item.is-active');
      if (activeNavItem) {
        activeNavItem.classList.remove('is-active');
      }

      navItem.classList.add('is-active');
      targetContent.classList.add('is-active');

      if (this.isMobile()) {
        // get header offset height
        const headerOffsetHeight = document.querySelector('.js-header').offsetHeight;

        // get body offset
        const bodyOffset = document.body.getBoundingClientRect();

        // scroll to active item
        ScrollToSection.scroll(navItem.getBoundingClientRect().top - bodyOffset.top - headerOffsetHeight);
      }

      this.refreshSwiper(targetContent);
    });
  }

  handleContentItems() {
    if (!this.contentItems) return;

    this.contentItems.forEach(contentItem => {
      this.handleContentItem(contentItem);
    });
  }

  handleContentItem(contentItem) {
    if (!contentItem) return;

    this.handleContentItemSwiper(contentItem);
  }

  handleContentItemSwiper(contentItem) {
    if (!contentItem) return;

    const swiperElement = contentItem.querySelector('.swiper-container');
    if (!swiperElement) return;

    let swiperOptions = {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 16,
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        992: {
          slidesPerView: 6,
          spaceBetween: 12,
        },
        1200: {
          slidesPerView: 6,
          spaceBetween: 24,
        }
      }
    };

    const speed = swiperElement.dataset.speed;
    if (speed) {
      swiperOptions = {
        ...swiperOptions,
        speed: parseInt(speed)
      };
    }

    const autoplaySpeed = swiperElement.dataset.autoplaySpeed;
    if (autoplaySpeed) {
      swiperOptions = {
        ...swiperOptions,
        autoplay: {
          delay: parseInt(autoplaySpeed)
        }
      };
    }

    const slidesPerGroup = swiperElement.dataset.slidesPerGroup;
    if (slidesPerGroup) {
      swiperOptions = {
        ...swiperOptions,
        slidesPerGroup: parseInt(slidesPerGroup)
      };
    }

    const paginationElement = contentItem.querySelector('.swiper-pagination');
    if (paginationElement) {
      swiperOptions = {
        ...swiperOptions,
        pagination: {
          el: paginationElement,
          clickable: true
        }
      };
    }

    new Swiper(swiperElement, swiperOptions);
  }

  refreshSwiper(targetContent) {
    if (!targetContent) return;

    const swiperElement = targetContent.querySelector('.swiper-container');
    if (!swiperElement) return;

    swiperElement.swiper.update();
  }

  isMobile() {
    return window.innerWidth <= 768;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-types-of-facilities-section');

  if (elements) {
    elements.forEach(element => {
      new TypesOfFacilitiesSection(element);
    });
  }
});
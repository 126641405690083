import axios from 'axios';
import qs from 'qs';
import Player from '../../molecules/player/player';
import { gsap } from "gsap";
import { ScrollToSection } from "../../../../../assets/private/js/scrollToSection";

class PlayerSectionV2 {
  constructor(element) {
    this.element = element;
    this.postID = element.dataset.postId;
    this.blockID = element.dataset.blockId;
    this.listItems = element.querySelectorAll('.js-player-section-v2__list-item');
    this.navigationItems = element.querySelectorAll('.js-player-section-v2__navigation-item');
    this.contentTtems = element.querySelectorAll('.js-player-section-v2__item');
    this.loadedItems = [];

    this.calcHeights();
    this.initObserver();
  }

  initObserver() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.initOnScroll();
          observer.unobserve(entry.target);
        }
      });
    }, {
      rootMargin: '0px 0px 0px 0px',
      threshold: 0
    });

    observer.observe(this.element);
  }

  initOnScroll() {
    this.handleInitialActiveItem();
    this.handleNavigationItemClick();
  }

  calcHeights() {
    if (this.isMobile() || !this.navigationItems) return;

    let navigationItemsHeight = 0;

    this.navigationItems.forEach(navigationItem => {
      navigationItemsHeight += navigationItem.offsetHeight;
    });

    const itemsWrapper = this.element.querySelector('.js-player-section-v2__items');
    if (!itemsWrapper) return;

    let itemsWrapperHeight = itemsWrapper.offsetHeight;

    if (itemsWrapperHeight > navigationItemsHeight) {
      this.element.classList.add('is-short-navigation');
    } else {
      this.element.classList.remove('is-short-navigation');
    }
  }

  handleInitialActiveItem() {
    const activeContentItem = this.getActiveContentItem();

    if (!activeContentItem) return;

    const playerWrapper = activeContentItem.querySelector('.js-player-section-v2__player-wrapper');

    if (!playerWrapper) return;

    const player = this.getPlayer();

    if (!player) return;

    player.then(player => {
      playerWrapper.innerHTML = player;

      const playerEl = playerWrapper.querySelector('.js-player');
      const chatEl = playerWrapper.querySelector('.js-chat');
      if (playerEl && chatEl) {
        new Player(playerEl, chatEl);
        this.loadedItems.push(activeContentItem);
      }
    }).catch(error => {
      console.error(error);
    });
  }

  getPlayer(recordingIndex = '0') {
    if (!this.postID || !this.blockID) return;

    const data = {
      'action': 'getPlayerSectionV2Data',
      'originUrl': window.location.href,
      'referrer': document.referrer,
      'postID': this.postID,
      'blockID': this.blockID,
      'recordingIndex': recordingIndex
    };

    let domain = window.location.hostname;
    let ajaxURL = '';

    // if domain contains localhost
    if (domain.includes('localhost')) {
      domain = 'http://localhost/talkie';
      ajaxURL = domain + '/web/wp/wp-admin/admin-ajax.php';
    } else {
      domain = 'https://' + domain;
      ajaxURL = domain + '/wp/wp-admin/admin-ajax.php';
    }

    const promise = new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: ajaxURL,
        data: qs.stringify(data)
      }).then(response => {
        if (response.data.success === false) {
          reject(response.data.data);
        } else {
          if (response.data.data.html) {
            resolve(response.data.data.html);
          }
        }
      }).catch(error => {
        reject(error);
      });
    });

    return promise;
  }

  handleNavigationItemClick() {
    if (!this.navigationItems) return;

    this.navigationItems.forEach(navigationItem => {
      navigationItem.addEventListener('click', () => {
        // return if its active item
        if (navigationItem.classList.contains('is-active')) return;

        const index = navigationItem.dataset.index;

        const activeNavigationItem = this.getActiveNavigationItem();
        if (activeNavigationItem) {
          this.removeActiveClass(activeNavigationItem);
        }

        this.addActiveClass(navigationItem);

        const activeListItem = this.getActiveListItem();
        if (activeListItem) {
          this.removeActiveClass(activeListItem);
        }

        const activeContentItem = this.getActiveContentItem();
        if (activeContentItem) {
          this.removeActiveClass(activeContentItem);
        }

        const newListItem = this.getListItemByIndex(index);
        if (newListItem) {
          this.addActiveClass(newListItem);
        }

        const newContentItem = this.getContentItemByIndex(index);
        if (newContentItem) {
          this.addActiveClass(newContentItem);

          if (!this.loadedItems.includes(newContentItem)) {
            if (!index) return;

            const playerWrapper = newContentItem.querySelector('.js-player-section-v2__player-wrapper');

            if (!playerWrapper) return;

            const player = this.getPlayer(index - 1);

            player.then(player => {
              playerWrapper.innerHTML = player;

              const playerEl = playerWrapper.querySelector('.js-player');
              const chatEl = playerWrapper.querySelector('.js-chat');
              if (playerEl && chatEl) {
                new Player(playerEl, chatEl);
                this.loadedItems.push(newContentItem);
              }
            }).catch(error => {
              console.error(error);
            });
          }

          this.animateContentItem(newContentItem);
        }

        this.calcHeights();
        this.scrollToActiveItem();
      });
    });
  }

  getActiveNavigationItem() {
    return this.element.querySelector('.js-player-section-v2__navigation-item.is-active');
  }

  getActiveListItem() {
    return this.element.querySelector('.js-player-section-v2__list-item.is-active');
  }

  getActiveContentItem() {
    return this.element.querySelector('.js-player-section-v2__item.is-active');
  }

  getListItemByIndex(index) {
    if (!index) return;

    return this.element.querySelector(`.js-player-section-v2__list-item[data-index="${index}"]`);
  }

  getContentItemByIndex(index) {
    if (!index) return;

    return this.element.querySelector(`.js-player-section-v2__item[data-index="${index}"]`);
  }

  addActiveClass(element) {
    if (!element) return;

    element.classList.add('is-active');
  }

  removeActiveClass(element) {
    if (!element) return;

    element.classList.remove('is-active');
  }

  isMobile() {
    return window.innerWidth <= 768;
  }

  animateContentItem(newContentItem) {
    const heading = newContentItem.querySelector('.js-player-section-v2__heading');
    const phoneCta = newContentItem.querySelector('.js-player-section-v2__phone-cta');
    const desc = newContentItem.querySelector('.js-player-section-v2__description');
    const quote = newContentItem.querySelector('.js-player-section-v2__quote');
    const buttons = newContentItem.querySelector('.js-player-section-v2__buttons');

    if (this.isMobile()) {
      const playerWrapper = newContentItem.querySelector('.js-player-section-v2__player-wrapper');

      gsap.timeline()
        .fromTo(heading, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 })
        .fromTo(phoneCta, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 }, '-=0.15')
        .fromTo(desc, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 }, '-=0.3')
        .fromTo(quote, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 }, '-=0.3')
        .fromTo(playerWrapper, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 }, '-=0.3')
        .fromTo(buttons, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 }, '-=0.3');
    } else {
      gsap.timeline()
        .fromTo(heading, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 })
        .fromTo(phoneCta, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 }, '-=0.15')
        .fromTo(desc, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 }, '-=0.3')
        .fromTo(quote, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 }, '-=0.3')
        .fromTo(buttons, { opacity: 0, y: 32 }, { opacity: 1, y: 0, duration: 0.3 }, '-=0.3');
    }
  }

  scrollToActiveItem() {
    // return if its not mobile
    if (!this.isMobile()) return;

    // get active list item
    const activeListItem = this.getActiveListItem();

    // return if there is no active list item
    if (!activeListItem) return;

    // get header offset height
    const headerOffsetHeight = document.querySelector('.js-header').offsetHeight;

    // get body offset
    const bodyOffset = document.body.getBoundingClientRect();

    // scroll to active list item
    ScrollToSection.scroll(activeListItem.getBoundingClientRect().top - bodyOffset.top - headerOffsetHeight);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-player-section-v2');
  if (elements) {
    elements.forEach(element => {
      new PlayerSectionV2(element);
    });
  }
});
import { ScrollToSection } from "../../../../../assets/private/js/scrollToSection";
import { CountUp } from 'countup.js';

class RoiCalculator {
  constructor(element) {
    this.section = element;
    this.form = element.querySelector('.js-roi-calculator__form');
    this.formButton = element.querySelector('.js-roi-calculator__form-button');
    this.resultsWrapper = element.querySelector('.js-roi-calculator__col--results-wrapper');
    this.results = element.querySelectorAll('.js-roi-calculator__result');
    this.ctaWrapper = element.querySelector('.js-roi-calculator__cta');

    this.settings = {
      noShowsThreshold: parseInt(element.dataset.noShowsThreshold),
      noShowReducer: parseInt(element.dataset.noShowReducer),
      monthlyBotCost: parseInt(element.dataset.monthlyBotCost),
      implementationCost: parseInt(element.dataset.implementationCost),
      currency: 'zł'
    };

    this.outputs = {
      additionalIncome: element.querySelector('.js-additional-income-output'),
      moreVisits: element.querySelector('.js-more-visits-output'),
      implementationCost: element.querySelector('.js-implementation-cost-output'),
      monthlyBotCost: element.querySelector('.js-monthly-bot-cost-output')
    };

    this.errors = {
      threshold: element.querySelector('.js-roi-calculator__error-message--threshold'),
      inputs: element.querySelector('.js-roi-calculator__error-message--inputs')
    };

    this.handleFormSubmit();
    this.run();
  }

  run() {
    this.hideErrorMessages();
    this.setInputs();
    this.showResults();
    this.showCta();
    this.setOutputsHTML();
  }

  handleFormSubmit() {
    if (!this.form) return;

    this.form.addEventListener('submit', (event) => {
      event.preventDefault();

      this.run();
      if (this.isMobile()) this.scrollToResults();
    });
  }

  scrollToResults() {
    if (!this.resultsWrapper) return;

    const headerOffsetHeight = document.querySelector('.js-header').offsetHeight;
    const bodyOffset = document.body.getBoundingClientRect();
    ScrollToSection.scroll(this.resultsWrapper.getBoundingClientRect().top - bodyOffset.top - headerOffsetHeight - 24);
  }

  setInputs() {
    const averageScheduledVisitsInput = this.section.querySelector('.js-average-scheduled-visits-input');
    const averageValueOfVisitsInput = this.section.querySelector('.js-average-value-of-visits-input');
    const averageNoShowsInput = this.section.querySelector('.js-average-no-shows-input');

    this.inputs = {};

    this.inputs.averageScheduledVisits = averageScheduledVisitsInput ? parseInt(averageScheduledVisitsInput.value) : 0;
    this.inputs.averageValueOfVisits = averageValueOfVisitsInput ? parseFloat(averageValueOfVisitsInput.value).toFixed(2) : 0;

    if (averageNoShowsInput.classList.contains('js-roi-calculator__form-input--select')) {
      this.inputs.averageNoShows = averageNoShowsInput ? parseInt(averageNoShowsInput.value) : 0;
    } else {
      this.inputs.averageNoShows = (averageNoShowsInput && this.inputs.averageScheduledVisits) ? (parseInt(averageNoShowsInput.value) * 100) / this.inputs.averageScheduledVisits : 0;
    }
  }

  setOutputsHTML() {
    const additionalIncomeValue = this.calcAdditionalIncome();
    const moreVisitsValue = this.calcMoreVisits();

    if (!additionalIncomeValue || !moreVisitsValue) {
      this.hideResults();
      this.hideCta();
      this.handleErrorMessages();
      return;
    }

    if (this.inputs.averageNoShows > 100) {
      this.hideResults();
      this.hideCta();
      this.handleErrorMessages();
      return;
    }

    if (this.outputs.additionalIncome) {
      const additionalIncome = new CountUp(this.outputs.additionalIncome, additionalIncomeValue, {
        separator: ' ',
        suffix: ` ${this.settings.currency}`,
        duration: 3,
      });
      if (!additionalIncome.error) {
        additionalIncome.start();
      } else {
        this.outputs.additionalIncome.innerHTML = additionalIncomeValue;
      }
    }

    if (this.outputs.moreVisits) {
      const moreVisits = new CountUp(this.outputs.moreVisits, moreVisitsValue, {
        separator: ' ',
        duration: 3,
      });
      if (!moreVisits.error) {
        moreVisits.start();
      } else {
        this.outputs.moreVisits.innerHTML = moreVisitsValue;
      }
    }

    if (this.outputs.implementationCost) {
      const implementationCost = new CountUp(this.outputs.implementationCost, this.settings.implementationCost, {
        separator: ' ',
        suffix: ` ${this.settings.currency}`,
        duration: 3,
      });
      if (!implementationCost.error) {
        implementationCost.start();
      } else {
        this.outputs.implementationCost.innerHTML = this.settings.implementationCost + ' ' + this.settings.currency;
      }
    }

    if (this.outputs.monthlyBotCost) {
      const monthlyBotCost = new CountUp(this.outputs.monthlyBotCost, this.settings.monthlyBotCost, {
        separator: ' ',
        suffix: ` ${this.settings.currency}`,
        duration: 3,
      });
      if (!monthlyBotCost.error) {
        monthlyBotCost.start();
      } else {
        this.outputs.monthlyBotCost.innerHTML = this.settings.monthlyBotCost.toLocaleString('pl-PL', { maximumFractionDigits: 0, useGrouping: true }) + ' ' + this.settings.currency;
      }
    }
  }

  calcAdditionalIncome() {
    this.additionalIncome = null;

    if (this.inputs.averageNoShows < this.settings.noShowsThreshold) {
      return;
    }

    let result = (this.inputs.averageScheduledVisits * (this.inputs.averageNoShows / 100) * this.inputs.averageValueOfVisits * 12) - (this.settings.monthlyBotCost * 12) - (12 * this.inputs.averageScheduledVisits * this.inputs.averageValueOfVisits * (this.calcNoShowsEvenWithBot() / 100)) - this.settings.implementationCost;

    if (result < 0) {
      return;
    }

    this.additionalIncome = result;

    return result;
  }

  calcMoreVisits() {
    if (!this.additionalIncome || this.additionalIncome <= 0) {
      return;
    }

    if (this.inputs.averageNoShows < this.settings.noShowsThreshold) {
      return;
    }

    let result = (((100 - this.calcNoShowsEvenWithBot()) / 100) * this.inputs.averageScheduledVisits * 12) - ((((100 - this.inputs.averageNoShows) / 100) * this.inputs.averageScheduledVisits * 12));

    return result;
  }

  showResults() {
    if (!this.results) return;

    this.results.forEach(result => {
      if (result.classList.contains('is-hidden')) {
        result.classList.remove('is-hidden');
      }
    });
  }

  hideResults() {
    if (!this.results) return;

    this.results.forEach(result => {
      result.classList.add('is-hidden');
    });
  }

  showCta() {
    if (!this.ctaWrapper) return;

    if (this.ctaWrapper.classList.contains('is-hidden')) {
      this.ctaWrapper.classList.remove('is-hidden');
    }
  }

  hideCta() {
    if (!this.ctaWrapper) return;

    this.ctaWrapper.classList.add('is-hidden');
  }

  handleErrorMessages() {
    const profitability = this.calcProfitability();

    if (!this.validInputs() || !profitability) {
      if (!this.errors.inputs) return;

      this.errors.inputs.classList.add('is-active');

      return;
    }

    if (this.inputs.averageNoShows > 100) {
      this.errors.inputs.classList.add('is-active');

      return;
    }

    if (profitability) {
      if (!this.errors.threshold) return;

      const thresholdErrorContent = this.errors.threshold.querySelector('.js-roi-calculator__error-message-content-percent');

      if (!thresholdErrorContent) return;

      thresholdErrorContent.innerHTML = ` ${profitability}% `;

      this.errors.threshold.classList.add('is-active');

      return;
    }
  }

  hideErrorMessages() {
    if (!this.errors) return;

    for (const [key, value] of Object.entries(this.errors)) {
      value.classList.remove('is-active');
    }
  }

  validInputs() {
    if (!this.inputs) return false;

    for (const [key, value] of Object.entries(this.inputs)) {
      if (!value || isNaN(value)) return false;
    }

    return true;
  }

  calcNoShowsEvenWithBot() {
    if (!this.inputs.averageNoShows || !this.settings.noShowReducer) return;

    return this.inputs.averageNoShows * (100 - this.settings.noShowReducer) / 100;
  }

  calcProfitability() {
    if (!this.inputs.averageValueOfVisits || !this.inputs.averageScheduledVisits) return;

    const result = this.inputs.averageValueOfVisits / this.inputs.averageScheduledVisits;
    let profitability = 60;

    if (result >= 0 && result < 0.05) {
      profitability = 80;
    } else if (result >= 0.05 && result < 0.1) {
      profitability = 79;
    } else if (result >= 0.1 && result < 0.15) {
      profitability = 78;
    } else if (result >= 0.15 && result < 0.20) {
      profitability = 77;
    } else if (result >= 0.20 && result < 0.25) {
      profitability = 76;
    } else if (result >= 0.25 && result < 0.30) {
      profitability = 75;
    } else if (result >= 0.30 && result < 0.35) {
      profitability = 74;
    } else if (result >= 0.35 && result < 0.40) {
      profitability = 73;
    } else if (result >= 0.40 && result < 0.45) {
      profitability = 72;
    } else if (result >= 0.45 && result < 0.50) {
      profitability = 71;
    } else if (result >= 0.50 && result < 0.55) {
      profitability = 70;
    } else if (result >= 0.55 && result < 0.60) {
      profitability = 69;
    } else if (result >= 0.60 && result < 0.65) {
      profitability = 68;
    } else if (result >= 0.65 && result < 0.70) {
      profitability = 67;
    } else if (result >= 0.70 && result < 0.75) {
      profitability = 66;
    } else if (result >= 0.75 && result < 0.80) {
      profitability = 65;
    } else if (result >= 0.80 && result < 0.85) {
      profitability = 64;
    } else if (result >= 0.85 && result < 0.90) {
      profitability = 63;
    } else if (result >= 0.90 && result < 0.95) {
      profitability = 62;
    } else if (result >= 0.95 && result < 1) {
      profitability = 61;
    }

    return profitability;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-roi-calculator');

  if (elements) {
    elements.forEach(element => {
      new RoiCalculator(element);
    });
  }
});
import Swiper from 'swiper/swiper-bundle';

class Quotations {
  constructor(element) {
    this.element = element;

    this.initSwiper();
  }

  initSwiper() {
    const swiperElement = this.element.querySelector('.swiper-container');
    const paginationElement = this.element.querySelector('.swiper-pagination');

    if (!swiperElement || !paginationElement) return;

    this.slider = new Swiper(swiperElement, {
      autoHeight: true,
      pagination: {
        el: paginationElement,
        clickable: true
      },
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.js-quotations');

  if (elements) {
    elements.forEach(element => {
      new Quotations(element);
    });
  }
});
export class ScrollToSection {

  static scroll(position) {
    var smoothScrollFeature = 'scrollBehavior' in document.documentElement.style;
    var i = parseInt(window.pageYOffset);
    if (i != position) {
      if (!smoothScrollFeature) {
        position = parseInt(position);
        if (i < position) {
          var int = setInterval(function () {
            if (i > (position - 20)) i += 1;
            else if (i > (position - 40)) i += 3;
            else if (i > (position - 80)) i += 8;
            else if (i > (position - 160)) i += 18;
            else if (i > (position - 200)) i += 24;
            else if (i > (position - 300)) i += 40;
            else i += 60;
            window.scroll(0, i);
            if (i >= position) clearInterval(int);
          }, 15);
        } else {
          var int = setInterval(function () {
            if (i < (position + 20)) i -= 1;
            else if (i < (position + 40)) i -= 3;
            else if (i < (position + 80)) i -= 8;
            else if (i < (position + 160)) i -= 18;
            else if (i < (position + 200)) i -= 24;
            else if (i < (position + 300)) i -= 40;
            else i -= 60;
            window.scroll(0, i);
            if (i <= position) clearInterval(int);
          }, 15);
        }
      } else {
        window.scroll({
          top: position,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const links = document.querySelectorAll('a[href^="#"]');
  if (links) {
    links.forEach(link => {
      link.addEventListener('click', (event) => {
        event.preventDefault();

        const link = event.target;
        const href = link.getAttribute("href");

        const targetSection = document.querySelector(href);
        let targetSectionOffset = 0;
        if (targetSection) {
          targetSectionOffset = targetSection.offsetTop;
        }

        const header = document.querySelector('.js-header');
        let headerHeight = 0;
        if (header) {
          headerHeight = header.offsetHeight;
        }

        ScrollToSection.scroll(targetSectionOffset - headerHeight);
      });
    });
  }

  // Scroll to section if URL contains '#'
  if (window.location.hash) {
    const targetSection = document.querySelector(window.location.hash);
    if (targetSection) {
      // wait 100ms
      setTimeout(() => {
        let targetSectionOffset = 0;
        if (targetSection) {
          targetSectionOffset = targetSection.offsetTop;
        }

        const header = document.querySelector('.js-header');
        let headerHeight = 0;
        if (header) {
          headerHeight = header.offsetHeight;
        }

        ScrollToSection.scroll(targetSectionOffset - headerHeight);
      }, 100);
    }
  }
});
class CaseStudySumUpSection {
  constructor(section) {
    if (!this.isMobile()) {
      this.section = section;
      this.aside = this.section.querySelector('.js-case-study-sum-up-section__aside');

      this.disableOverflow();
      this.adjustAsideTopPosition();
    }
  }

  disableOverflow() {
    const body = document.querySelector('body');

    if (!body) return;

    body.style.overflow = 'visible';
  }

  adjustAsideTopPosition() {
    if (!this.aside) return;

    const header = document.querySelector('.js-header');

    if (!header) return;

    const headerHeight = header.offsetHeight;

    this.aside.style.top = `${headerHeight + 16}px`;
  }

  isMobile() {
    return window.innerWidth < 992;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('.js-case-study-sum-up-section');

  if (sections) {
    sections.forEach(section => {
      new CaseStudySumUpSection(section);
    });
  }
});
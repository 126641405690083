class OurTargetGroup {
  constructor(section) {
    this.section = section;
    this.items = section.querySelectorAll('.js-our-target-group-item');

    if (window.innerWidth > 791) {
      this.handleItemsHeight();
    }
  }

  handleItemsHeight() {
    if (!this.items) return;

    let maxRow1Height = 1;

    this.items.forEach(item => {
      const row1 = item.querySelector('.js-our-target-group-item__row--1');

      if (!row1) return;

      if (maxRow1Height < row1.offsetHeight) {
        maxRow1Height = row1.offsetHeight;
      }
    });

    this.items.forEach(item => {
      const row1 = item.querySelector('.js-our-target-group-item__row--1');

      if (!row1) return;

      row1.style.minHeight = `${maxRow1Height}px`;
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const sections = document.querySelectorAll('.js-our-target-group');
  if (sections) {
    sections.forEach(section => {
      new OurTargetGroup(section);
    });
  }
});
import { gsap } from "gsap";

export default class Player {
  constructor(playerElement, chatElement) {
    this.playerCol = playerElement.parentElement.parentElement;
    this.player = playerElement;
    this.chat = chatElement;
    this.playButton = this.player.querySelector('.js-player__button');
    this.audio = this.player.querySelector('audio');
    this.seekSlider = this.player.querySelector('.js-player__seek-slider');
    this.changeButtons = this.playerCol.querySelectorAll('.js-player-section__change-button');
    this.navigationItems = this.playerCol.parentElement.parentElement.querySelectorAll('.js-player-section-v2__navigation-item');

    this.state = 'play';
    this.animationFrame = null;
    this.scrolled = null;

    this.animationTimeline = gsap.timeline({ paused: true });

    this.playButton.addEventListener('click', this.handlePlayerButton);

    if (this.changeButtons) {
      this.changeButtons.forEach(changeButton => {
        changeButton.addEventListener('click', this.handleChangeButton);
      });
    }

    if (this.navigationItems) {
      this.navigationItems.forEach(navigationItem => {
        navigationItem.addEventListener('click', this.handleNavigationItem);
      });
    }

    this.seekSlider.addEventListener('change', this.handleSeekSliderChange);

    this.audio.addEventListener('ended', this.handleAudioEnd);

    this.seekSlider.addEventListener('input', (e) => this.handleSeekSliderInput(e));

    if (this.audio.readyState > 0) {
      this.setSliderMax();
      this.setupAnimationTimeline();
    } else {
      this.audio.addEventListener('loadedmetadata', () => {
        this.setSliderMax();
        this.setupAnimationTimeline();
      });
    }
    this.updateScroll();

    const playerSections = document.querySelectorAll('.js-player-section');
    if (playerSections) {
      playerSections.forEach(playerSection => {
        const playerSectionButtons = playerSection.querySelectorAll('.js-player-section__change-button');
        if (playerSectionButtons) {
          playerSectionButtons.forEach(button => {
            button.addEventListener('click', () => {
              setTimeout(() => {
                this.updateMessagesPosition();
              }, 50);
            });
          });
        }
      });
    }

    this.updateMessagesPosition();
  }

  showRangeProgress = (rangeInput) => {
    this.player.style.setProperty('--seek-before-width', rangeInput.value / rangeInput.max * 100 + '%');
    this.animationTimeline.totalProgress(rangeInput.value / rangeInput.max);
  }

  setSliderMax = () => {
    this.seekSlider.max = Math.floor(this.audio.duration);
  }

  handleAudioEnd = () => {
    this.audio.pause();
    this.seekSlider.value = Math.floor(this.seekSlider.max);
    this.player.style.setProperty('--seek-before-width', `${this.seekSlider.value / this.seekSlider.max * 100}%`);
    cancelAnimationFrame(this.animationFrame);
    gsap.ticker.remove(this.updateChatAnimation);
    this.state = 'play';
    this.playButton.classList.remove('is-playing');
  }

  handleSeekSliderChange = () => {
    this.audio.currentTime = this.seekSlider.value;
    if (!this.audio.paused) {
      requestAnimationFrame(this.whilePlaying);
      gsap.ticker.add(this.updateChatAnimation);
    }
    this.updateScroll();
  }

  handleSeekSliderInput = (event) => {
    this.showRangeProgress(event.target);
    if (!this.audio.paused) {
      cancelAnimationFrame(this.animationFrame);
      gsap.ticker.remove(this.updateChatAnimation);
    }
    this.updateScroll();
  }

  handlePlayerButton = () => {
    if (this.state === 'play') {
      this.audio.play();
      requestAnimationFrame(this.whilePlaying);
      gsap.ticker.add(this.updateChatAnimation);
      this.state = 'pause';
      this.playButton.classList.add('is-playing');
    } else {
      this.audio.pause();
      cancelAnimationFrame(this.animationFrame);
      gsap.ticker.remove(this.updateChatAnimation);
      this.state = 'play';
      this.playButton.classList.remove('is-playing');
    }
  }

  handleChangeButton = () => {
    if (this.state === 'pause') {
      this.audio.pause();
      cancelAnimationFrame(this.animationFrame);
      gsap.ticker.remove(this.updateChatAnimation);
      this.state = 'play';
      this.playButton.classList.remove('is-playing');
    }
    this.setupAnimationTimeline();
  }

  handleNavigationItem = () => {
    if (this.state === 'pause') {
      this.audio.pause();
      cancelAnimationFrame(this.animationFrame);
      gsap.ticker.remove(this.updateChatAnimation);
      this.state = 'play';
      this.playButton.classList.remove('is-playing');
    }
    this.setupAnimationTimeline();
  }

  whilePlaying = () => {
    this.seekSlider.value = Math.floor(this.audio.currentTime);
    this.player.style.setProperty('--seek-before-width', `${this.seekSlider.value / this.seekSlider.max * 100}%`);
    this.animationFrame = requestAnimationFrame(this.whilePlaying);
  }

  updateChatAnimation = () => {
    this.animationTimeline.totalProgress(this.audio.currentTime / this.audio.duration);
    this.updateScroll();
  }

  updateScroll = () => {
    if (!this.scrolled) {
      this.chat.scrollTop = this.chat.scrollHeight;
    }
  }

  updateMessagesPosition = () => {
    const messagesWithAdditionalMessages = this.chat.querySelectorAll('.js-chat__message.has-additional-messages');
    if (messagesWithAdditionalMessages) {
      messagesWithAdditionalMessages.forEach(messageWithAdditionalMessages => {
        let isHumanMessage = false;
        let isAvatar = false;
        if (messageWithAdditionalMessages.classList.contains('chat__message--left')) {
          isHumanMessage = true;
        }
        if (messageWithAdditionalMessages.classList.contains('is-avatar')) {
          isAvatar = true;
        }
        const additionalMessagesContainer = messageWithAdditionalMessages.nextElementSibling;
        const additionalMessages = additionalMessagesContainer.querySelectorAll('.js-chat__additional-message');
        if (additionalMessages) {
          let maxWidth = 0;

          additionalMessages.forEach(additionalMessage => {
            const initialDisplay = additionalMessage.style.display;

            additionalMessage.style.display = 'block';

            if (maxWidth < additionalMessage.offsetWidth) {
              maxWidth = additionalMessage.offsetWidth;
            }

            additionalMessage.style.display = initialDisplay;
          });


          if (isHumanMessage) {
            messageWithAdditionalMessages.style.minWidth = maxWidth + 64 + 'px';
            if (isAvatar) {
              additionalMessagesContainer.style.minWidth = maxWidth + 16 + 'px';
            } else {
              additionalMessagesContainer.style.minWidth = maxWidth + 48 + 'px';
            }
          } else {
            messageWithAdditionalMessages.style.minWidth = maxWidth + 'px';
          }
        }
      });
    }
  }

  setupAnimationTimeline = () => {
    const messageElements = Array.from(this.chat.querySelectorAll('.js-chat__message'));
    const blueTypingElement = this.chat.querySelector('.js-chat__message-blue-typing');

    const firstMessage = this.chat.querySelector('.js-chat__message-1');

    gsap.set(firstMessage, { display: 'block' });

    if (blueTypingElement.dataset.showOnStart) {
      gsap.set(blueTypingElement, { display: 'block' });
    }

    messageElements.forEach((element, index) => {
      let typingElementSelector = this.chat.querySelector('.js-chat__message-white-typing');

      if (element.dataset.messagePosition === 'left') {
        typingElementSelector = this.chat.querySelector('.js-chat__message-blue-typing');
      }

      if (element.dataset.typingDisplay) {
        this.animationTimeline.to(typingElementSelector, { duration: 1, display: 'block', opacity: 1 }, element.dataset.typingDisplay);
        this.animationTimeline.to(typingElementSelector, { display: 'none' }, element.dataset.messageDisplay - 0.5);
      }
      this.animationTimeline.to(element, { duration: 1, display: 'block', opacity: 1 }, element.dataset.messageDisplay);

      const additionalMessageElements = element.parentElement.querySelectorAll('.js-chat__additional-message');
      if (additionalMessageElements) {
        additionalMessageElements.forEach(element => {
          this.animationTimeline.to(element, { duration: 1, display: 'block', opacity: 1 }, element.dataset.messageDisplay);
        });
      }
    });
    this.animationTimeline.to(messageElements[0], { duration: 1, x: 0 }, Math.floor(this.audio.duration));
    this.updateScroll();
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const playerElements = document.querySelectorAll('.js-player');
  const chatElements = document.querySelectorAll('.js-chat');

  for (let i = 0; i < playerElements.length; i++) {
    if (playerElements[i] && chatElements[i]) {
      new Player(playerElements[i], chatElements[i]);
    }
  }
});
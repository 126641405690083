class NewsBar {
  constructor(element) {
    this.element = element;
    this.header = document.querySelector('.js-header');
    this.closeButton = element.querySelector('.js-news-bar__close');
    this.pageLayoutWrapper = document.querySelector('.js-l-wrapper');

    this.showHandler();

    this.elementHeight = this.getNewsBarHeight();
    this.adjustBlogSidebarHeight();

    if (this.closeButton) {
      this.closeButton.addEventListener('click', () => this.closeHandler());
    }

    if (this.pageLayoutWrapper) {
      // Get first section
      const firstSection = this.pageLayoutWrapper.querySelector('.l-section');
      if (firstSection) {
        const firstSectionStyles = getComputedStyle(firstSection);
        if (firstSectionStyles) {
          const currentPaddingTop = parseInt(firstSectionStyles.paddingTop);
          firstSection.style.paddingTop = `${currentPaddingTop + this.elementHeight}px`;
        }
      }
    }
  }

  getNewsBarHeight() {
    return this.element.offsetHeight;
  }

  adjustBlogSidebarHeight() {
    const blogSidebar = document.querySelector('.js-blog-sidebar');

    if (blogSidebar) {
      const blogSidebarStyles = getComputedStyle(blogSidebar);
      let currentTopValue = parseInt(blogSidebarStyles.top);

      blogSidebar.style.top = `${currentTopValue + this.elementHeight}px`;
    }
  }

  closeHandler() {
    sessionStorage.setItem('is-news-bar-closed', 'yes');
    this.element.classList.add('is-hidden');
    if (this.header) {
      this.header.classList.remove('is-news-bar');
    }
    this.elementHeight = this.getNewsBarHeight();
    this.adjustBlogSidebarHeight();
  }

  showHandler() {
    if (sessionStorage.getItem('is-news-bar-closed') != 'yes') {
      this.element.classList.remove('is-hidden');
    } else {
      if (this.header) {
        this.header.classList.remove('is-news-bar');
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelector('.js-news-bar');

  if (element) {
    new NewsBar(element);
  }
});
class AudioButton {
    constructor(element) {
        this.element = element;
        this.statusIcons = element.querySelectorAll('.js-audio-button__status-icon');
        this.audioFile = element.querySelector('.js-audio-button__file');
        this.progressLayer = element.querySelector('.js-audio-button__progress-layer');

        this.audioFile.addEventListener('timeupdate', () => this.updateProgressLayer());
        this.audioFile.addEventListener('play', () => this.toggleIcons());
        this.audioFile.addEventListener('pause', () => this.toggleIcons());
        this.element.addEventListener('click', () => this.toggleAudio());
    }

    updateProgressLayer() {
        let currentTime = this.audioFile.currentTime;
        let duration = this.audioFile.duration;
        let percentageValue = (currentTime / duration) * 100;

        this.progressLayer.style.width = `${percentageValue}%`;
    }

    toggleIcons() {
        if (this.statusIcons) {
            this.statusIcons.forEach(statusIcon => {
                statusIcon.classList.toggle('a-audio-button__icon--hidden');
            });
        }
    }

    toggleAudio() {
        if (this.audioFile.paused) {
            this.audioFile.play();
        } else {
            this.audioFile.pause();
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const elements = document.querySelectorAll('.js-audio-button');

    if (elements) {
        elements.forEach(element => {
            new AudioButton(element);
        });
    }
});